import Modal from '@/components/common/Modal'
import { Title, Subtitle, TextArea, Input } from './styles'
import TempPasswordModalHook from './TempPasswordModal.hook'
import { formatTime } from '@/libs/date'
import { useDeleteTempPassword } from '@/services/tempPassword/useDeleteTempPassword'
import { useRecoilValue } from 'recoil'
import policyDetailAtom from '@/recoil/policyDetail'
import { useEffect } from 'react'

const CreateSection = ({
  reason,
  setReason,
  isReasonError,
}: {
  reason: string
  setReason: (v: string) => void
  isReasonError: boolean
}) => {
  return (
    <div>
      <Title>สร้างรหัสผ่านชั่วคราว</Title>
      <Subtitle>เหตุผลในการสร้างรหัสผ่านชั่วคราว</Subtitle>
      <div>
        <TextArea
          status={isReasonError ? 'error' : ''}
          placeholder="ระบุว่าต้องการเปลี่ยนสำหรับ support เคสไหนบ้าง เช่น เช็กเคสการได้รับ notification"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
    </div>
  )
}

const PreviewSection = ({
  tempPassword,
  expiredAt,
}: {
  tempPassword: string
  expiredAt: Date | null
}) => {
  return (
    <div>
      <Title>รหัสผ่านชั่วคราว</Title>
      <Input value={tempPassword} />
      <Subtitle>รหัสผ่านนี้ใช้ได้ถึงเวลา {expiredAt ? formatTime(expiredAt) : ''} น.</Subtitle>
    </div>
  )
}

const TempPasswordModal = ({
  open,
  onCancel,
  canView,
  userId,
  tempPassword,
  expiredAt,
}: {
  open: boolean
  onCancel: () => void
  canView: boolean
  userId: string
  tempPassword: string
  expiredAt: Date | null
}) => {
  const policyDetail = useRecoilValue(policyDetailAtom)
  const { handleOkButton, reason, setReason, isReasonError, refetchUseTempPasswordStatus } =
    TempPasswordModalHook()
  const deleteTempPassword = useDeleteTempPassword(policyDetail.id || '')

  useEffect(() => {
    if (deleteTempPassword.isSuccess || deleteTempPassword.isError) {
      onCancel()
      refetchUseTempPasswordStatus()
    }
  }, [deleteTempPassword.isSuccess, deleteTempPassword.isError])

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={() =>
        handleOkButton({ canView, userId, reason, onDeleted: () => deleteTempPassword.mutate() })
      }
      okText={canView ? 'ลบรหัสผ่านชั่วคราว' : 'ยืนยัน'}
      cancelText={canView ? 'ปิด' : 'ยกเลิก'}
      closable={false}
      okButtonProps={{ disabled: canView ? false : !reason, loading: deleteTempPassword.isPending }}
    >
      {canView ? (
        <PreviewSection tempPassword={tempPassword} expiredAt={expiredAt} />
      ) : (
        <CreateSection reason={reason} setReason={setReason} isReasonError={isReasonError} />
      )}
    </Modal>
  )
}

export default TempPasswordModal
