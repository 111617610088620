import styled from '@emotion/styled'
import CommonButton from '@/components/common/Button'
import { Form as AntForm } from 'antd'

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`

export const CreatedByText = styled('div')`
  color: #666666;
  font-size: 14px;
`

export const CreatedByTextValue = styled('span')`
  color: #000000;
  margin: 0px 5px;
`

export const ActionBlock = styled('div')``

export const ButtonAction = styled(CommonButton)``

export const Title = styled('div')`
  font-weight: 400;
  font-size: 24px;
`

export const Form = styled(AntForm)``
