import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { Form, UploadFile } from 'antd'
import _ from 'lodash'

import ConfirmModalHook from '@/components/common/ConfirmModal/ConfirmModal.hook'
import { UpdateActiveHealthPartnerInput } from '@/gql/activeHealthPartner/backofficeUpdateActiveHealthPartner/interface'
import { usePartnerLogoUpload } from '@/services/wellBeing/usePartnerLogoUpload'
import { activeHealthPartnerGql } from '@/gql'
import message from '@/libs/message'

const WellBeingPartnerPageHook = () => {
  const [form] = Form.useForm()
  const { partnerId } = useParams<{ partnerId: string }>()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [logoPartnerFiles, setLogoPartnerFiles] = useState<UploadFile[]>([])
  const [isLoading, setLoading] = useState<boolean>(false)
  const { setConfirmModal } = ConfirmModalHook()

  // Fetch partner data
  const [
    backofficeGetActiveHealthPartnerById,
    { data: partnerData, loading: partnerLoading, refetch },
  ] = useLazyQuery(activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerById, {
    fetchPolicy: 'no-cache',
  })
  const getActiveHealthPartnerById = useCallback(
    (id: string) => {
      backofficeGetActiveHealthPartnerById({
        variables: {
          input: {
            id: id,
            relations: ['activeHealthPartnerCategory'],
          },
        },
      })
    },
    [backofficeGetActiveHealthPartnerById],
  )

  // Response partner data
  const getPartnerData = () => ({
    displayNameTh: partnerData?.backofficeGetActiveHealthPartnerById.displayNameTh,
    displayNameEn: partnerData?.backofficeGetActiveHealthPartnerById.displayNameEn,
    categoryId: partnerData?.backofficeGetActiveHealthPartnerById.activeHealthPartnerCategory.id,
    isActive: partnerData?.backofficeGetActiveHealthPartnerById.isActive,
  })

  // Handle button save
  const handleFormComplete = () => {
    const values = form.getFieldsValue()
    const isEmpty = Object.values(values || []).some((item: any) => item == null)
    const isSame = _.isEqual(values, getPartnerData())
    const isLogoUpdated = logoPartnerFiles?.length > 0
    setIsButtonDisabled((isEmpty || isSame) && !isLogoUpdated)
  }

  // Use GraphQL mutation for updating partner data
  const [mutationUpdatePartner, { loading: isMutationUpdateLoading }] = useMutation(
    activeHealthPartnerGql.mutation.backofficeUpdateActiveHealthPartner,
    {
      fetchPolicy: 'no-cache',
    },
  )

  // Function to update partner details
  const updatePartner = async (id: string, formValues: any) => {
    try {
      const { displayNameTh, displayNameEn, categoryId, isActive } = formValues
      const input: UpdateActiveHealthPartnerInput = {
        id,
        displayNameTh,
        displayNameEn,
        categoryId,
        isActive,
      }

      await mutationUpdatePartner({ variables: { input } })
      message.success({ content: 'แก้ไขพาร์ทเนอร์สำเร็จ', duration: 10 })
    } catch (error: any) {
      message.error(error?.message || 'กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง')
    }
  }

  // Update partner logo
  const { mutateAsync: uploadLogo } = usePartnerLogoUpload(partnerId)

  // Handle update partner
  const handleUpdatePartner = async (): Promise<void> => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (!partnerId) return

      if (logoPartnerFiles.length > 0) {
        // Update file status to "uploading"
        setLogoPartnerFiles(
          logoPartnerFiles.map((upload) => ({ ...upload, percent: 60, status: 'uploading' })),
        )

        // Upload the first file
        await uploadLogo(logoPartnerFiles[0].originFileObj as File)

        // Message update logo
        message.success({ content: 'แก้ไขโลโก้พาร์ทเนอร์สำเร็จ', duration: 10 })
      }

      if (!_.isEqual(values, getPartnerData())) {
        await updatePartner(partnerId, values)
      }

      refetch()
    } catch (error: any) {
      message.error({ content: error?.response?.data?.message || 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง' })
    } finally {
      // Clear files after successful upload
      setLogoPartnerFiles([])
      setLoading(false)
    }
  }

  // Form submit handler
  const onSubmit = async (): Promise<void> => {
    setConfirmModal({
      onOkFunction: () => {
        setConfirmModal({
          isVisible: false,
          onOkFunction: Function,
          dataTestId: undefined,
        })
        handleUpdatePartner()
      },
      isVisible: true,
      title: 'คุณได้มีการแก้ไขข้อมูล',
      bodyText: 'ต้องการบันทึกการแก้ไขทั้งหมดหรือไม่',
      okText: 'บันทึก',
      cancelText: 'ไม่ต้องบันทึก',
      dataTestId: 'update-partner-information-modal',
    })
  }

  useEffect(() => {
    if (!partnerId) return
    handleFormComplete()
  }, [logoPartnerFiles, partnerId])

  useEffect(() => {
    if (!partnerId) return
    getActiveHealthPartnerById(partnerId)
  }, [getActiveHealthPartnerById, partnerId])

  useEffect(() => {
    if (!partnerData) return
    form.setFieldsValue(getPartnerData())
    handleFormComplete()
  }, [partnerData])

  return {
    form,
    partnerId,
    partnerData,
    logoPartnerFiles,
    isButtonDisabled,
    isMutationUpdateLoading,
    partnerLoading,
    isLoading,
    handleFormComplete,
    setLogoPartnerFiles,
    onSubmit,
  }
}

export default WellBeingPartnerPageHook
