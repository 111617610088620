import { WellBeingModeEnum } from '@/constant/WELL_BEING'
import {
  Block,
  Container,
  PartnerLogo,
  Title,
  FormItem,
  Input,
  FormGridBlock,
  Box,
  Select,
  Upload,
  HintUpload,
  PreviewPartnerLogo,
  IconSvg,
  IconWrapper,
} from './styles'
import WellBeingPartnerInfoHook from './WellBeingPartnerInfo.hook'
import { IMAGE_URL } from '@/constant/IMAGE'
import { UploadFile } from 'antd'

const WellBeingPartnerInfo = ({
  displayMode,
  originLogoUrl,
  logoFiles,
  setLogoFiles,
}: {
  displayMode: WellBeingModeEnum
  originLogoUrl?: string
  logoFiles?: UploadFile[]
  setLogoFiles?: Function
}) => {
  const { categoryOptions, uploadProps } = WellBeingPartnerInfoHook({ logoFiles, setLogoFiles })
  const isEditMode = displayMode === WellBeingModeEnum.EDIT

  return (
    <Container>
      <Title>ข้อมูลพาร์ทเนอร์</Title>
      <Block>
        <Box>
          <FormItem label="โลโก้พาร์ทเนอร์*">
            {isEditMode ? (
              <Upload
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                  removeIcon: (
                    <IconWrapper>
                      <IconSvg src={IMAGE_URL.deleteGreyIcon} />
                    </IconWrapper>
                  ),
                }}
                {...uploadProps}
              >
                {uploadProps.fileList?.length === 0 && (
                  <PreviewPartnerLogo
                    key={originLogoUrl}
                    alt="Partner Logo"
                    width="140px"
                    height="140px"
                    src={originLogoUrl}
                    preview={{
                      visible: false,
                      mask: (
                        <IconWrapper>
                          <IconSvg src={IMAGE_URL.editGreyIcon} />
                        </IconWrapper>
                      ),
                    }}
                  />
                )}
              </Upload>
            ) : (
              <PartnerLogo src={originLogoUrl} />
            )}
          </FormItem>
        </Box>
        <Box style={{ marginLeft: '28px' }}>
          <FormGridBlock>
            <FormItem
              label="ชื่อพาร์ทเนอร์ (ไทย)*"
              name="displayNameTh"
              normalize={(value) => (value === '' ? undefined : value)}
            >
              <Input disabled={!isEditMode} />
            </FormItem>
            <FormItem
              label="ชื่อพาร์ทเนอร์ (อังกฤษ)"
              name="displayNameEn"
              normalize={(value) => (value === '' ? undefined : value)}
            >
              <Input disabled={!isEditMode} />
            </FormItem>
          </FormGridBlock>
          <FormItem label="หมวดหมู่ (Category)*" name="categoryId" style={{ marginTop: '28px' }}>
            <Select placeholder="เลือกหมวดหมู่" options={categoryOptions} disabled={!isEditMode} />
          </FormItem>
        </Box>
      </Block>
      {isEditMode && (
        <HintUpload>
          รูป Logo ต้องเป็นไฟล์ .jpg, .jpeg หรือ .png ไม่เกิน 100 K ขนาด 200 x 200
        </HintUpload>
      )}
    </Container>
  )
}

export default WellBeingPartnerInfo
