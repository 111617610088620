import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { Badge, TablePaginationConfig } from 'antd'
import { PresetStatusColorType } from 'antd/lib/_util/colors'
import { FilterValue } from 'antd/es/table/interface'
import { ColumnsType } from 'antd/lib/table'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'
import dayjs from 'dayjs'

import { UserSelectStyle } from '@/components/common/Table/styles'
import { isArraysHaveSameValues } from '@/libs/utils/is-arrays-have-same-values'
import { useOrderPagination } from '@/services/order/useOrderPagination/index'
import { OrderManagementContext } from '@/contexts'
import { ActionBlock, BoxStatus, ButtonIcon, Status } from './styles'
import { IMAGE_URL } from '@/constant/IMAGE'
import CONSTANT from '@/constant'
import {
  AddressPolicyPrintTypeEnum,
  OrderStatusEnum,
  OrderTabEnum,
  ProductTypeEnum,
  PRODUCT_TYPE,
  ORDER_TAB,
  ApplicationFormStatusEnum,
  PAYMENT_TYPE,
} from '@/constant/TA_TRANSACTION'

import { validatePerm } from '@/libs/validatePermission'
import { findOrderTab } from '@/libs/utils/find-order-tab'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { isTimesEqual } from '@/libs/utils/is-times-equal'
import _ from 'lodash'

const inputChange = new BehaviorSubject('')
const inputChange$ = inputChange.asObservable()

const TaTransactionTableHook = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const user = useRecoilValue(userAtom)
  const { orderTab } = useContext(OrderManagementContext)
  const [search, setSearch] = useState<string>('')
  const [currentSearch, setCurrentSearch] = useState<string>('')
  const [filters, setFilters] = useState<Record<string, FilterValue | null>>({})
  const [selectProductTypeCodes, setProductTypeCodes] = useState<ProductTypeEnum[]>([])
  const [selectOrderTabs, setOrderTabs] = useState<OrderTabEnum[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentPageSize, setCurrentPageSize] = useState<number>(10)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSendMailPolicy, setSelectedSendMailPolicy] = useState<{
    email: string
    applicationFormId: string
  }>()

  const options = {
    [OrderTabEnum.DRAFT]: {
      placeholder: 'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ผู้เดินทาง, เบอร์โทร, เลขบัตรประชาชน',
      searchBy: [
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.WAITING_PAYMENT]: {
      placeholder: 'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ผู้เดินทาง, เบอร์โทร, เลขบัตรประชาชน',
      searchBy: [
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.PAYMENT_FAILED]: {
      placeholder: 'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ผู้เดินทาง, เบอร์โทร, เลขบัตรประชาชน',
      searchBy: [
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.PAYMENT_SUCCESS]: {
      placeholder:
        'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ชื่อผู้เดินทาง, เบอร์โทรศัพท์, เลขที่กรมธรรม์, เลขบัตรประชาชน',
      searchBy: [
        'policyNumber',
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.FAIL_VERIFIED]: {
      placeholder: 'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ผู้เดินทาง, เบอร์โทร, เลขบัตรประชาชน',
      searchBy: [
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.CANCELLED]: {
      placeholder: 'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ผู้เดินทาง, เบอร์โทร, เลขบัตรประชาชน',
      searchBy: [
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
    [OrderTabEnum.ALL]: {
      placeholder:
        'ค้นหาเลขที่คำสั่งซื้อ, ชื่อผู้ติดต่อ, ชื่อผู้เดินทาง, เบอร์โทรศัพท์, เลขที่กรมธรรม์, เลขบัตรประชาชน',
      searchBy: [
        'policyNumber',
        'order.orderNumber',
        'order.contactPerson.firstname',
        'order.contactPerson.lastname',
        'order.contactPerson.mobileNumber',
        'applicationForm.firstname',
        'applicationForm.lastname',
        'applicationForm.mobileNumber',
        'applicationForm.identityId',
        'applicationForm.passportId',
      ],
    },
  }
  const placeholder: string = options[orderTab].placeholder || ''

  const fetchOrderPagination = useOrderPagination({
    query: {
      tab: orderTab,
      productTypeCode: selectProductTypeCodes.length > 0 ? selectProductTypeCodes : undefined,
      filterTab:
        orderTab === OrderTabEnum.ALL && selectOrderTabs.length > 0 ? selectOrderTabs : undefined,
    },
    pagination: {
      search: search ? search : undefined,
      searchBy: search ? options[orderTab].searchBy : undefined,
      page: currentPage,
      limit: currentPageSize,
    },
  })

  const orderTabBadgeStatus = {
    [OrderTabEnum.DRAFT]: 'warning',
    [OrderTabEnum.WAITING_PAYMENT]: 'processing',
    [OrderTabEnum.PAYMENT_SUCCESS]: 'success',
    [OrderTabEnum.PAYMENT_FAILED]: 'error',
    [OrderTabEnum.FAIL_VERIFIED]: 'error',
    [OrderTabEnum.CANCELLED]: 'error',
    [OrderTabEnum.ALL]: 'default',
  }

  const onSelectOrder = (id: string, tab: OrderTabEnum | null) => {
    navigate(
      generatePath(CONSTANT.ROUTES.TA_TRANSACTION_ORDER_ID_PAGE, {
        orderId: id,
      }),
      {
        state: {
          referrer: CONSTANT.ROUTES.TA_TRANSACTION_PAGE,
          data: {
            search,
            filters,
            selectProductTypeCodes,
            selectOrderTabs,
            currentPage,
            currentPageSize,
            tab,
          },
        },
      },
    )
  }

  const columns: ColumnsType<object> = [
    {
      width: 75,
      title: 'ลำดับ',
      align: 'center',
      render: (_value, _record, index) => (currentPage - 1) * currentPageSize + index + 1,
    },
    {
      title: 'เลขที่คำสั่งซื้อ',
      dataIndex: ['order', 'orderNumber'],
      className: UserSelectStyle,
    },
    {
      title: 'วันที่สั่งซื้อ',
      dataIndex: ['order', 'orderDate'],
      className: UserSelectStyle,
      render: (orderDate: Date) => {
        return dayjs(orderDate).format('DD-MM-YYYY HH:mm:ss')
      },
    },
    [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(orderTab)
      ? {
          title: 'เลขที่กรมธรรม์',
          dataIndex: ['policyNumber'],
          className: UserSelectStyle,
          render: (policyNumber) => {
            return policyNumber ?? '-'
          },
        }
      : { width: 0 },
    [
      OrderTabEnum.DRAFT,
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.CANCELLED,
      OrderTabEnum.ALL,
    ].includes(orderTab)
      ? {
          title: 'จำนวนผู้เดินทาง',
          className: UserSelectStyle,
          render: (orderItem) => {
            return orderItem?.order?.totalOrderItem > 1
              ? `${orderItem?.applicationForm?.insuredNo + 1}/${orderItem?.order?.totalOrderItem}`
              : orderItem?.order?.totalOrderItem
          },
        }
      : { width: 0 },
    {
      title: 'ชื่อผู้ติดต่อ',
      dataIndex: ['order', 'contactPerson'],
      className: UserSelectStyle,
      render: (contactPerson) => {
        return `${contactPerson.title.titleEn} ${contactPerson.firstname} ${contactPerson.lastname}`
      },
    },
    {
      title: 'เบอร์โทรผู้ติดต่อ',
      dataIndex: ['order', 'contactPerson', 'mobileNumber'],
      className: UserSelectStyle,
    },
    {
      title: 'อีเมลผู้ติดต่อ',
      dataIndex: ['order', 'contactPerson', 'email'],
      className: UserSelectStyle,
    },
    {
      title: 'ชื่อผู้เดินทาง',
      dataIndex: ['applicationForm'],
      className: UserSelectStyle,
      render: (applicationForm) => {
        return `${applicationForm?.title?.titleEn} ${applicationForm.firstname} ${applicationForm.lastname}`
      },
    },
    {
      title: 'เบอร์โทรผู้เดินทาง',
      dataIndex: ['applicationForm', 'mobileNumber'],
      className: UserSelectStyle,
    },
    {
      title: 'ID/Passport No.',
      dataIndex: ['applicationForm'],
      className: UserSelectStyle,
      render: (applicationForm) => {
        return applicationForm.identityId || applicationForm.passportId
      },
    },
    {
      title: 'แผนประกัน',
      dataIndex: ['order', 'plan'],
      render: (plan) => {
        return `ประกันภัยการเดินทาง ${plan?.product?.nameTh} ${plan?.code} ${plan?.nameTh}`
      },
      className: UserSelectStyle,
    },
    {
      key: 'productTypes',
      title: 'ประเภทประกันภัย',
      dataIndex: ['order', 'plan', 'product', 'type', 'nameTh'],
      className: UserSelectStyle,
      filters: [
        {
          text: PRODUCT_TYPE[ProductTypeEnum.TRAVEL_TRIP],
          value: ProductTypeEnum.TRAVEL_TRIP,
        },
        {
          text: PRODUCT_TYPE[ProductTypeEnum.TRAVEL_ANNUAL],
          value: ProductTypeEnum.TRAVEL_ANNUAL,
        },
      ],
      filteredValue: filters['productTypes'] || null,
    },
    {
      title: 'ค่าเบี้ย/ผู้เดินทาง',
      className: UserSelectStyle,
      render: (orderItem) => {
        return orderItem.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    },
    {
      title: 'ยอดค่าเบี้ยชำระรวม',
      dataIndex: ['order'],
      className: UserSelectStyle,
      render: (order) => {
        return order.premiumAmount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      },
    },
    {
      title: 'วันเริ่มความคุ้มครอง',
      dataIndex: ['order', 'effectiveDate'],
      className: UserSelectStyle,
      render: (effectiveDate) => {
        return dayjs(effectiveDate).format('DD-MM-YYYY HH:mm')
      },
    },
    {
      title: 'วันสิ้นสุดความคุ้มครอง',
      dataIndex: ['order', 'expiryDate'],
      className: UserSelectStyle,
      render: (expiryDate) => {
        return isTimesEqual(expiryDate, '23:59')
          ? dayjs(expiryDate).format('DD-MM-YYYY 24:00')
          : dayjs(expiryDate).format('DD-MM-YYYY HH:mm')
      },
    },
    [
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.ALL,
    ].includes(orderTab)
      ? {
          title: 'ประเภทการชำระ',
          className: UserSelectStyle,
          dataIndex: ['order', 'payment'],
          render: (payment) => {
            const paymentTypeId: keyof typeof PAYMENT_TYPE = payment?.paymentType?.id
            const paymentTypeName = PAYMENT_TYPE[paymentTypeId] ?? '-'

            return paymentTypeName === PAYMENT_TYPE[4]
              ? `${paymentTypeName} ${payment?.omiseMobileBanking?.abbreviation || ''}`
              : paymentTypeName
          },
        }
      : { width: 0 },
    [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(orderTab)
      ? {
          title: 'หมายเหตุการชำระ',
          className: UserSelectStyle,
          dataIndex: ['order', 'payment', 'status'],
          render: (status) => {
            return status ?? '-'
          },
        }
      : { width: 0 },
    [
      OrderTabEnum.DRAFT,
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.ALL,
    ].includes(orderTab)
      ? {
          title: 'โปรโมชั่นหมดอายุ',
          dataIndex: ['order', 'promotionExpire'],
          className: UserSelectStyle,
          render: (promotionExpire) => {
            return promotionExpire ? dayjs(promotionExpire).format('DD-MM-YYYY') : '-'
          },
        }
      : { width: 0 },
    [
      OrderTabEnum.DRAFT,
      OrderTabEnum.WAITING_PAYMENT,
      OrderTabEnum.PAYMENT_FAILED,
      OrderTabEnum.PAYMENT_SUCCESS,
      OrderTabEnum.ALL,
    ].includes(orderTab)
      ? {
          title: 'PROMO CODE',
          dataIndex: ['order', 'promotionCode'],
          className: UserSelectStyle,
          render: (promotionCode) => {
            return promotionCode ?? '-'
          },
        }
      : { width: 0 },
    [OrderTabEnum.ALL].includes(orderTab)
      ? {
          key: 'orderTabs',
          title: 'สถานะ',
          className: UserSelectStyle,
          filters: Object.values(OrderTabEnum)
            .filter((tab) => tab !== OrderTabEnum.ALL)
            .map((tab) => {
              return {
                text: ORDER_TAB[tab],
                value: tab,
              }
            }),
          filteredValue: filters['orderTabs'] || null,
          render: (orderItem) => {
            const orderTab = findOrderTab(
              orderItem?.order?.status,
              orderItem?.applicationForm?.status,
              orderItem?.order?.effectiveDate,
            )
            return orderTab ? (
              <BoxStatus>
                <Badge status={orderTabBadgeStatus[orderTab] as PresetStatusColorType} />
                <Status>{ORDER_TAB[orderTab]}</Status>
              </BoxStatus>
            ) : (
              '-'
            )
          },
        }
      : { width: 0 },
    [OrderTabEnum.FAIL_VERIFIED, OrderTabEnum.ALL].includes(orderTab)
      ? {
          title: 'เหตุผลไม่ผ่านการตรวจสอบ',
          className: UserSelectStyle,
          dataIndex: ['applicationForm', 'status'],
          render: (status) => {
            return status === ApplicationFormStatusEnum.FAIL_VERIFIED
              ? 'ไม่ผ่าน AMLO (ไม่แจ้งลูกค้าโดยตรง ปรึกษา Innovation Tech Support)'
              : '-'
          },
        }
      : { width: 0 },
    [OrderTabEnum.PAYMENT_SUCCESS, OrderTabEnum.ALL].includes(orderTab)
      ? {
          title: 'รูปแบบจัดส่ง กธ.',
          className: UserSelectStyle,
          dataIndex: ['addressPolicy'],
          render: (addressPolicy) => {
            const policyPrintType = addressPolicy?.policyPrintType
            return policyPrintType === AddressPolicyPrintTypeEnum.E_POLICY
              ? 'อีเมล'
              : policyPrintType === AddressPolicyPrintTypeEnum.POLICY
              ? 'ไปรษณีย์'
              : '-'
          },
        }
      : { width: 0 },
    {
      key: 'app',
      title: 'ซื้อผ่านช่องทาง',
      className: UserSelectStyle,
      width: 150,
      filters: [
        {
          text: 'แอปพลิเคชัน',
          value: 'APP',
        },
      ],
      filteredValue: null,
      render: () => {
        return 'แอปพลิเคชัน'
      },
      // TODO when sync e-com (filter)
    },
    {
      fixed: 'right',
      width: 75,
      render: (orderItem) => {
        const isLink =
          false && validatePerm([BACKOFFICE_USER_PERMISSION.TA010102], user.permissions) // TODO when sync e-com
        const isSendMail =
          validatePerm([BACKOFFICE_USER_PERMISSION.TA010103], user.permissions) &&
          OrderStatusEnum.PAYMENT_CONFIRMED === orderItem?.order?.status &&
          AddressPolicyPrintTypeEnum.E_POLICY === orderItem?.addressPolicy?.policyPrintType &&
          ApplicationFormStatusEnum.POLICY_ISSUED === orderItem?.applicationForm?.status &&
          !_.isEmpty(orderItem?.documentUrlS3Path)

        const tab = findOrderTab(
          orderItem?.order?.status,
          orderItem?.applicationForm?.status,
          orderItem?.order?.effectiveDate,
        )
        const isOrderPage = validatePerm(
          [BACKOFFICE_USER_PERMISSION.TA010201, BACKOFFICE_USER_PERMISSION.TA010204],
          user.permissions,
        )
        return (
          <ActionBlock>
            {isSendMail ? (
              <ButtonIcon
                src={IMAGE_URL.sendMailIcon}
                onClick={() =>
                  openModal(orderItem?.addressPolicy?.email, orderItem?.applicationForm?.id)
                }
              />
            ) : undefined}
            {isLink ? <ButtonIcon src={IMAGE_URL.copyLinkIcon} onClick={() => {}} /> : undefined}
            {isOrderPage ? (
              <ButtonIcon
                src={IMAGE_URL.policyManagementIcon}
                onClick={() => onSelectOrder(orderItem?.order?.id, tab)}
              />
            ) : undefined}
          </ActionBlock>
        )
      },
    },
  ]

  const handleOnChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCurrentSearch(value)
    inputChange.next(value)
  }

  const handleOnChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    // set pagination
    const { current, pageSize } = pagination
    if (current && pageSize) {
      setCurrentPage(current)
      setCurrentPageSize(pageSize)
    }

    setFilters(filters)
    // set filter product type code
    const productTypeCodes: ProductTypeEnum[] = (filters['productTypes'] || [])
      .filter(
        (value): value is string | number => typeof value === 'string' || typeof value === 'number',
      )
      .map((value): ProductTypeEnum | undefined => {
        const code = value.toString() as ProductTypeEnum
        const isProductType = Object.values(ProductTypeEnum).includes(code)

        return isProductType ? code : undefined
      })
      .filter((element): element is ProductTypeEnum => element !== undefined)

    const isSameType = isArraysHaveSameValues(productTypeCodes, selectProductTypeCodes)
    if (!isSameType) {
      setProductTypeCodes(productTypeCodes)
    }

    // set filter order tab
    const orderTabs: OrderTabEnum[] = (filters['orderTabs'] || [])
      .filter(
        (value): value is string | number => typeof value === 'string' || typeof value === 'number',
      )
      .map((value): OrderTabEnum | undefined => {
        const code = value.toString() as OrderTabEnum
        const isOrderTab = Object.values(OrderTabEnum).includes(code)

        return isOrderTab ? code : undefined
      })
      .filter((element): element is OrderTabEnum => element !== undefined)
    const isSameTab = isArraysHaveSameValues(orderTabs, selectOrderTabs)
    if (!isSameTab) {
      setOrderTabs(orderTabs)
    }
  }

  const openModal = (email: string, applicationFormId: string) => {
    setSelectedSendMailPolicy({ email, applicationFormId })
    setIsModalOpen(true)
  }

  const cancelModal = () => {
    setIsModalOpen(false)
    setSelectedSendMailPolicy({ email: '', applicationFormId: '' })
  }

  useEffect(() => {
    const subscription = inputChange$
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((value) => {
        setSearch(value)
        setCurrentPage(1)
      })

    return () => {
      // Reset the BehaviorSubject value when the component unmounts
      inputChange.next('')
      return subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    setCurrentPage(1)
  }, [orderTab])

  useEffect(() => {
    const data = location.state?.data
    if (data) {
      const {
        search,
        filters,
        selectProductTypeCodes,
        selectOrderTabs,
        currentPage,
        currentPageSize,
      } = data
      setFilters(filters)
      setProductTypeCodes(selectProductTypeCodes)
      setOrderTabs(selectOrderTabs)
      setCurrentPage(currentPage)
      setCurrentPageSize(currentPageSize)
      setCurrentSearch(search)
      setSearch(search)
      inputChange.next(search)
    }
  }, [location.state])

  return {
    fetchOrderPagination,
    placeholder,
    columns,
    handleOnChangeSearch,
    handleOnChangeTable,
    currentSearch,
    currentPage,
    currentPageSize,
    selectedSendMailPolicy,
    isModalOpen,
    cancelModal,
  }
}

export default TaTransactionTableHook
