import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { fetchCreateTempPassword } from '@/services/tempPassword/useCreateTempPassword'
import message from '@/libs/message'
import { useRecoilValue } from 'recoil'
import policyDetailAtom from '@/recoil/policyDetail'

const TempPasswordModalHook = () => {
  const policyDetail = useRecoilValue(policyDetailAtom)
  const [reason, setReason] = useState('')
  const queryClient = useQueryClient()
  const [isReasonError, setIsReasonError] = useState(false)

  const refetchUseTempPasswordStatus = () => {
    queryClient.refetchQueries({ queryKey: ['useTempPasswordStatus', { userId: policyDetail.id }] })
  }

  const refetchUseTempPassword = () => {
    queryClient.refetchQueries({ queryKey: ['useTempPassword', { userId: policyDetail.id }] })
  }

  const handleOkButton = ({
    canView,
    userId,
    reason,
    onDeleted,
  }: {
    userId: string
    reason: string
    canView: boolean
    onDeleted: () => void
  }) => {
    if (canView) {
      onDeleted()
    } else {
      if (!reason) {
        setIsReasonError(true)
        return
      }

      fetchCreateTempPassword(userId, reason)
        .then(() => {
          refetchUseTempPasswordStatus()
          refetchUseTempPassword()
          setReason('')
          setIsReasonError(false)
        })
        .catch((error) => {
          if (error.response?.data) {
            const code = error.response.data.code
            const backofficeUser = error.response.data.data?.backofficeUser

            const messageError: { [key: string]: string } = {
              BIZUCTP10001: `มีการสร้างรหัสผ่านชั่วคราวไปแล้ว โดย ${
                backofficeUser?.firstname || ''
              } ${backofficeUser?.lastname || ''}`,
              BIZUCTP10002: 'คุณได้เปลี่ยนรหัสผ่านชั่วคราวเกิน 10 คน/วัน',
            }

            message.warning({
              content: messageError[code],
            })
          }
        })
    }
  }

  return { handleOkButton, reason, setReason, isReasonError, refetchUseTempPasswordStatus }
}

export default TempPasswordModalHook
