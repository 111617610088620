import { formatLocaleDate } from '@/libs/utils/format-locale-date.util'
import { PeriodWithIdType } from '@/services/coverage/useCoveragesPeriod/types'
import dayjs from 'dayjs'

const TimelineChartHook = ({ data }: { data?: PeriodWithIdType[] }) => {
  const formatLocale = (date: Date) => formatLocaleDate(date, 'th', 'D MMM BBBB, HH:mm')

  const formatLabelTime = (date: dayjs.ConfigType) => dayjs(date).format('HH:mm')

  const getTripSummary = (
    data: PeriodWithIdType[] = [],
  ): { minStartTime: string; maxEndTime: string; totalDuration: number } => {
    if (data.length === 0) {
      return { minStartTime: '-', maxEndTime: '-', totalDuration: 0 }
    }

    let minStartTime = Infinity
    let maxEndTime = -Infinity
    let totalDuration = 0

    data.forEach(({ startDateTime, endDateTime, duration }) => {
      const start = new Date(startDateTime).getTime()
      const end = new Date(endDateTime).getTime()

      minStartTime = Math.min(minStartTime, start)
      maxEndTime = Math.max(maxEndTime, end)
      totalDuration += duration
    })

    return {
      minStartTime: formatLocale(new Date(minStartTime)),
      maxEndTime: formatLocale(new Date(maxEndTime)),
      totalDuration,
    }
  }

  const calculateTripWidth = (duration: number, totalDuration: number, width: number) =>
    (duration / totalDuration) * width

  const formatEventDetails = (start: Date, end: Date) => ({
    startDate: formatLocale(start),
    endDate: formatLocale(end),
    period: dayjs(end).diff(start, 'm'),
  })

  const roundToNearestFive = (timeInMs: number) => {
    const date = dayjs(timeInMs)
    const minutes = date.minute()
    const roundedMinutes = Math.round(minutes / 5) * 5
    return date.minute(roundedMinutes).second(0).millisecond(0)
  }

  const { minStartTime, maxEndTime, totalDuration } = getTripSummary(data)

  return {
    minStartTime,
    maxEndTime,
    totalDuration,
    calculateTripWidth,
    formatEventDetails,
    formatLabelTime,
    roundToNearestFive,
  }
}

export default TimelineChartHook
