import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { TempPasswordResponseType } from './types'
import { useQuery } from '@tanstack/react-query'

const fetchTempPassword = async (userId: string) => {
  const response = await httpClientPrivate.get<TempPasswordResponseType>(
    `${config.restUrl}/v1/backoffice/user/${userId}/temp-password`,
  )

  return response.data
}

const useTempPassword = (query: { userId: string }) => {
  const queryKey = ['useTempPassword', query]
  const queryFn = async () => fetchTempPassword(query.userId)
  const queryResult = useQuery({ queryFn, queryKey, enabled: !!query.userId })
  return { ...queryResult }
}

export { fetchTempPassword, useTempPassword }
