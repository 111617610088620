import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeRemoveActiveHealthPartnerBranchDataInterface,
  BackofficeRemoveActiveHealthPartnerBranchInputInterface,
} from './interface'

const backofficeRemoveActiveHealthPartnerBranch: TypedDocumentNode<
  BackofficeRemoveActiveHealthPartnerBranchDataInterface,
  BackofficeRemoveActiveHealthPartnerBranchInputInterface
> = gql`
  mutation BackofficeRemoveActiveHealthPartnerBranch($activeHealthPartnerBranchId: ID!) {
    backofficeRemoveActiveHealthPartnerBranch(
      activeHealthPartnerBranchId: $activeHealthPartnerBranchId
    ) {
      code
      message
      data
    }
  }
`

export default backofficeRemoveActiveHealthPartnerBranch
