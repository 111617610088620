import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateActiveHealthPartnerBranchDataInterface,
  BackofficeUpdateActiveHealthPartnerBranchInputInterface,
} from './interface'

const backofficeUpdateActiveHealthPartnerBranch: TypedDocumentNode<
  BackofficeUpdateActiveHealthPartnerBranchDataInterface,
  BackofficeUpdateActiveHealthPartnerBranchInputInterface
> = gql`
  mutation BackofficeUpdateActiveHealthPartnerBranch(
    $input: UpdateActiveHealthPartnerBranchInput!
  ) {
    backofficeUpdateActiveHealthPartnerBranch(input: $input) {
      id
      displayNameTh
      displayNameEn
      qr {
        id
        isActive
        imageUrl
      }
      activeHealthPartnerBranchContacts {
        id
        phoneNumber
        extensionNumber
      }
    }
  }
`

export default backofficeUpdateActiveHealthPartnerBranch
