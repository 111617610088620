import React, { useEffect } from 'react'

import {
  Container,
  CreateTempPasswordButton,
  EditIcon,
  Form,
  FormItem,
  Information,
  InformationContainer,
  InformationTitle,
  Input,
  ModalSubtitle,
  ModalTitle,
  Name,
  UpdateUserButton,
} from './styles'
import CustomerProfileHook, { EditModeEnum } from './CustomerProfile.hook'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import Modal from '@/components/common/Modal'
import { Skeleton } from 'antd'
import { SearchStateEnum } from '@/components/feature/PolicyDetailPage/SearchPolicyBox/SearchPolicyBox.hook'
import { IMAGE_URL } from '@/constant/IMAGE'
import { Rule } from 'rc-field-form/lib/interface'
import TempPasswordModal from '../TempPasswordModal'

interface CustomerProfileInterface {
  isComponentLoading: boolean
  searchState: SearchStateEnum
}

function CustomerProfile({ isComponentLoading, searchState }: CustomerProfileInterface) {
  const {
    policyDetail,
    updateUser,
    user,
    editMode,
    modalProperty,
    openModal,
    closeModal,
    form,
    onFormChange,
    disableSave,
    canViewTempPassword,
    setCanViewTempPassword,
    fetchTempPassword,
    fetchTempPasswordStatus,
    openTempPasswordModal,
    toggleTempPasswordModal,
    onSetTempPassword,
    tempPassword,
    expiredAt,
    closeTempPasswordModal,
  } = CustomerProfileHook()

  useEffect(() => {
    if (fetchTempPasswordStatus.data?.isCreated) {
      if (fetchTempPassword.data?.tempPassword) {
        onSetTempPassword(fetchTempPassword.data.tempPassword, fetchTempPassword.data.expiredAt)
        setCanViewTempPassword(true)
      }
    } else {
      setCanViewTempPassword(false)
    }
  }, [fetchTempPasswordStatus.data?.isCreated, fetchTempPassword.data?.tempPassword])

  return (
    <Container
      visible={searchState === SearchStateEnum.SEARCHING || searchState === SearchStateEnum.FINISH}
      data-testid="policy-detail-customer-profile-card"
    >
      <Modal
        open={!!editMode}
        footer={null}
        onCancel={closeModal}
        data-testid={editMode ? modalProperty[editMode].dataTestId : undefined}
      >
        <ModalTitle>{editMode ? modalProperty[editMode].title : undefined}</ModalTitle>
        <ModalSubtitle>{editMode ? modalProperty[editMode].subtitle : undefined}</ModalSubtitle>

        <Form
          layout="vertical"
          form={form}
          onFinish={updateUser}
          onFieldsChange={onFormChange}
          initialValues={{
            currentEmail: policyDetail.email,
            currentMobileNumber: policyDetail.mobileNumber,
          }}
        >
          <FormItem
            label={editMode ? modalProperty[editMode].form.currentValueInput.label : undefined}
            required
            name={editMode ? modalProperty[editMode].form.currentValueInput.name : undefined}
          >
            <Input
              disabled
              data-testid={
                editMode ? modalProperty[editMode].form.currentValueInput.dataTestId : undefined
              }
            />
          </FormItem>
          <FormItem
            label={editMode ? modalProperty[editMode].form.newValueInput.label : undefined}
            name={editMode ? modalProperty[editMode].form.newValueInput.name : undefined}
            rules={
              editMode ? (modalProperty[editMode].form.newValueInput.rule as Rule[]) : undefined
            }
          >
            <Input
              type={editMode ? modalProperty[editMode].form.newValueInput.type : undefined}
              maxLength={editMode === EditModeEnum.MOBILE_NUMBER ? 10 : undefined}
              autoComplete="off"
              placeholder={
                editMode ? modalProperty[editMode].form.newValueInput.placeholder : undefined
              }
              data-testid={
                editMode ? modalProperty[editMode].form.newValueInput.dataTestId : undefined
              }
            />
          </FormItem>

          <FormItem>
            <UpdateUserButton type="primary" htmlType="submit" disabled={disableSave}>
              ยืนยัน
            </UpdateUserButton>
            <UpdateUserButton onClick={closeModal}>ยกเลิก</UpdateUserButton>
          </FormItem>
        </Form>
      </Modal>

      <Skeleton loading={isComponentLoading} active>
        <Name data-testid="policy-detail-insuredName">{policyDetail.insuredName}</Name>
        <InformationContainer repeatCount={policyDetail.deleteRequestedAt ? 7 : 6}>
          <div>
            <InformationTitle>เลขประจำตัวประชาชน</InformationTitle>
            <Information data-testid="policy-detail-identityId">
              {policyDetail.identityId}
            </Information>
          </div>
          <div>
            <InformationTitle>เบอร์โทรศัพท์</InformationTitle>
            <Information data-testid="policy-detail-mobileNumber">
              {policyDetail.mobileNumber ? (
                <>
                  {policyDetail.mobileNumber}{' '}
                  {validatePerm([BACKOFFICE_USER_PERMISSION.CUS010103], user.permissions) ? (
                    <EditIcon
                      src={IMAGE_URL.editIcon}
                      onClick={() => openModal(EditModeEnum.MOBILE_NUMBER)}
                    />
                  ) : undefined}
                </>
              ) : undefined}
            </Information>
          </div>
          <div>
            <InformationTitle>อีเมล</InformationTitle>
            <Information data-testid="policy-detail-insuredName">
              {policyDetail.email ? (
                <>
                  {policyDetail.email}{' '}
                  {validatePerm([BACKOFFICE_USER_PERMISSION.CUS010102], user.permissions) ? (
                    <EditIcon
                      src={IMAGE_URL.editIcon}
                      onClick={() => openModal(EditModeEnum.EMAIL)}
                    />
                  ) : undefined}
                </>
              ) : undefined}
            </Information>
          </div>
          <div>
            <InformationTitle>วันที่ลงทะเบียน</InformationTitle>
            <Information data-testid="policy-detail-registeredDate">
              {policyDetail.registeredDate}
            </Information>
          </div>
          <div>
            <InformationTitle>สถานะบัญชี</InformationTitle>
            <Information
              isOrangeText={!!policyDetail.deleteRequestedAt}
              data-testid="policy-detail-account-status"
            >
              {policyDetail.deleteRequestedAt ? 'ระงับใช้งาน' : 'ใช้งาน'}
            </Information>
          </div>
          {policyDetail.deleteRequestedAt ? (
            <div>
              <InformationTitle>วันที่แจ้งลบบัญชี</InformationTitle>
              <Information data-testid="policy-detail-deleteRequestedAt">
                {policyDetail.deleteRequestedAt}
              </Information>
            </div>
          ) : undefined}
          {validatePerm([BACKOFFICE_USER_PERMISSION.CUS010105], user.permissions) ? (
            <div>
              {canViewTempPassword ? (
                <CreateTempPasswordButton onClick={toggleTempPasswordModal}>
                  ดูรหัสผ่านชั่วคราว
                </CreateTempPasswordButton>
              ) : (
                <CreateTempPasswordButton onClick={toggleTempPasswordModal}>
                  สร้างรหัสผ่านชั่วคราว
                </CreateTempPasswordButton>
              )}
            </div>
          ) : undefined}
        </InformationContainer>
      </Skeleton>
      <TempPasswordModal
        open={openTempPasswordModal}
        onCancel={closeTempPasswordModal}
        canView={canViewTempPassword}
        userId={policyDetail.id || ''}
        tempPassword={tempPassword}
        expiredAt={expiredAt}
      />
    </Container>
  )
}

export default CustomerProfile
