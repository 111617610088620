import { ColumnsType } from 'antd/lib/table'
import { useReturnTimeReason } from '@/services/returnTimeReason/useReturnTimeReason'
import { toThaiLocaleShortDate } from '@/libs/date'
import dayjs from 'dayjs'
import { Badge } from 'antd'

import { TVI_STATUS_COLORS, TVI_STATUS_TEXTS, TviStatusEnum } from '@/constant/RETURN_TIME'

type ReturnTimeReasonModalProps = { ppuMotorReturnTimeId: string }

function ReturnTimeReasonModalHook({ ppuMotorReturnTimeId }: ReturnTimeReasonModalProps) {
  const returnTimeReason = useReturnTimeReason({ ppuMotorReturnTimeId })
  const columns: ColumnsType<{}> = [
    {
      title: 'วันที่เริ่ม',
      dataIndex: 'start',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาเริ่ม',
      dataIndex: 'start',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    {
      title: 'วันที่สิ้นสุด',
      dataIndex: 'end',
      render: (value) => {
        return toThaiLocaleShortDate(new Date(value))
      },
    },
    {
      title: 'เวลาสิ้นสุด',
      dataIndex: 'end',
      render: (value) => {
        return dayjs(value).format('HH:mm')
      },
    },
    { title: 'นาที', dataIndex: 'minutes' },
    {
      title: 'สถานะ',
      dataIndex: 'reason',
      render: (value) => {
        return (
          <>
            <Badge
              color={TVI_STATUS_COLORS[value as TviStatusEnum] || '#0290FF'}
              text={TVI_STATUS_TEXTS[value as TviStatusEnum] || 'N/A'}
            />
          </>
        )
      },
    },
  ]
  return { columns, returnTimeReason }
}

export default ReturnTimeReasonModalHook
