import Table from '@/components/common/Table'
import {
  Container,
  SearchInput,
  Title,
  SearchIcon,
  ContainerAction,
  ButtonIcon,
  ButtonAction,
} from './styles'
import WellBeingPartnerTableHook from './WellBeingPartnerTable.hook'
import WellBeingPartnerModal from '../WellBeingPartnerModal'
import { IMAGE_URL } from '@/constant/IMAGE'

const WellBeingPartnerTable = () => {
  const {
    activeHealthPartnerLoading,
    activeHealthPartnerData,
    isAddPartner,
    isModalOpen,
    pagination,
    columns,
    onTableChange,
    onTablePaginate,
    onSearchInputChange,
    handleAddPartner,
    handleModalOk,
    handleModalCancel,
  } = WellBeingPartnerTableHook()

  return (
    <Container>
      <Title>รายชื่อพาร์ทเนอร์</Title>
      <ContainerAction>
        <SearchInput
          id="well-being-search"
          data-testid="well-being-search-input"
          prefix={<SearchIcon />}
          placeholder="ค้นหาพาร์ทเนอร์"
          onChange={() => onSearchInputChange(true)}
        />
        {isAddPartner && (
          <ButtonAction
            id="well-being-partner-add"
            type="primary"
            icon={<ButtonIcon src={IMAGE_URL.plusIcon} />}
            onClick={handleAddPartner}
          >
            เพิ่มพาร์ทเนอร์
          </ButtonAction>
        )}
      </ContainerAction>
      <Table
        rowKey="id"
        data-testid="well-being-partner-table"
        loading={activeHealthPartnerLoading}
        columns={columns}
        dataSource={activeHealthPartnerData?.backofficeGetActiveHealthPartnerPagination.data}
        scroll={{ x: true }}
        pagination={{
          pageSizeOptions: [10, 25, 50, 100],
          showSizeChanger: true,
          total: pagination.totalItems,
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.limit,
          onChange: (page, pageSize) => onTablePaginate(page, pageSize),
        }}
        onChange={(_pagination, _filter, sorter) => onTableChange(sorter)}
      />
      <WellBeingPartnerModal
        isModalOpen={isModalOpen}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
      />
    </Container>
  )
}

export default WellBeingPartnerTable
