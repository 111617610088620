import backofficeGetActiveHealthPartnerBranchById from './backofficeGetActiveHealthPartnerBranchById/backofficeGetActiveHealthPartnerBranchById.gql'
import backofficeCreateActiveHealthPartnerBranch from './backofficeCreateActiveHealthPartnerBranch/backofficeCreateActiveHealthPartnerBranch.gql'
import backofficeUpdateActiveHealthPartnerBranch from './backofficeUpdateActiveHealthPartnerBranch/backofficeUpdateActiveHealthPartnerBranch.gql'
import backofficeRemoveActiveHealthPartnerBranch from './backofficeRemoveActiveHealthPartnerBranch/backofficeRemoveActiveHealthPartnerBranch.gql'

const activeHealthPartnerBranch = {
  query: {
    backofficeGetActiveHealthPartnerBranchById,
  },
  mutation: {
    backofficeCreateActiveHealthPartnerBranch,
    backofficeUpdateActiveHealthPartnerBranch,
    backofficeRemoveActiveHealthPartnerBranch,
  },
}

export default activeHealthPartnerBranch
