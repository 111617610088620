import {
  Button as AntdButton,
  Form as AntdForm,
  Input as FormInput,
  Select as AntdSelect,
  Upload as AntdUpload,
} from 'antd'
import styled from '@emotion/styled'

export const Container = styled('div')``

export const Button = styled(AntdButton)``

export const Form = styled(AntdForm)``

export const FormItem = styled(AntdForm.Item)`
  margin-bottom: 16px;

  label {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0 0 4px;
    white-space: initial;
    text-align: start;
  }
`

export const Input = styled(FormInput)`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
`

export const Select = styled(AntdSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 5px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 5px 0px !important;
  }

  .ant-select-selection-search {
    padding: 5px 0px !important;
  }
`

export const Upload = styled(AntdUpload)`
  .ant-upload-list-picture-card:has(.ant-upload-list-item-container) .ant-upload-select {
    display: none !important;
  }

  .ant-upload-select {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    width: 160px !important;
    height: 160px !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 15px !important;
    background: transparent !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    overflow: hidden !important;
  }

  .ant-upload-list-item-container {
    width: 160px !important;
    height: 160px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .ant-upload-list-item {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 15px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;

    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out !important;
  }

  .ant-upload-list-item::before {
    background: rgba(0, 0, 0, 0.5) !important;
    width: 100% !important;
    height: 100% !important;
  }

  .ant-upload-list-item-actions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-progress-bg {
    background-color: #4caf50 !important; /* Green progress bar */
    border-radius: 10px;
  }
`

export const IconSvg = styled('img')`
  height: 20px;
  width: 20px;
`

export const IconWrapper = styled('div')`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 40px !important;
  height: 40px !important;
  background: rgba(255, 255, 255) !important;
  border-radius: 50% !important;
  color: white !important;
  cursor: pointer !important;
`

export const UploadWrapper = styled('div')`
  display: grid;
  place-items: center;
  padding: 10px;
`

export const UploadIcon = styled('img')`
  height: 40px;
  width: 40px;
`

export const UploadTextWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  font-size: 14px;
  margin-top: 4px;
`

export const UploadText = styled('div')`
  color: #009640;
  font-weight: 500;
  margin-right: 4px;
`

export const UploadTextDetail = styled('div')`
  font-size: 12px;
  color: #666666;
`
