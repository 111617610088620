import { useCallback, useEffect, useState } from 'react'
import { UploadFile, UploadProps } from 'antd'
import { useLazyQuery } from '@apollo/client'

import { mapSelectOptions } from '@/libs/utils/map-select-options.util'
import { activeHealthPartnerGql } from '@/gql'
import message from '@/libs/message'

const WellBeingPartnerInfoHook = ({
  logoFiles = [],
  setLogoFiles,
}: {
  logoFiles?: UploadFile[]
  setLogoFiles?: Function
}) => {
  const [categoryOptions, setCategoryOptions] = useState<
    { label: string; value: string }[] | undefined
  >([])

  const [backofficeGetActiveHealthPartnerCategoryPagination, { data: categoryData }] = useLazyQuery(
    activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerCategoryPagination,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const getActiveHealthPartnerCategoryPagination = useCallback(() => {
    backofficeGetActiveHealthPartnerCategoryPagination({
      variables: {
        input: {},
      },
    })
  }, [backofficeGetActiveHealthPartnerCategoryPagination])

  const uploadProps: UploadProps = {
    fileList: logoFiles,
    maxCount: 1,
    accept: '.png,.jpg,.jpeg',
    beforeUpload: () => false,
    onChange({ file, fileList }) {
      if (fileList.length < 1) {
        return
      }

      // Validate file size (must be less than 100KB)
      const isLt100KB = file.size && file.size / 1024 < 100
      if (!isLt100KB) {
        message.error('File must smaller than 100KB!')
        return
      }

      const reader = new FileReader()

      // `file` is of type `UploadFile<any>` from Ant Design, but readAsDataURL() requires a Blob or File.
      reader.readAsDataURL(file as unknown as Blob)
      reader.onload = (event) => {
        const img = new Image()
        img.src = event.target?.result as string
        img.onload = () => {
          // Validate image dimensions (must be exactly 200x200 pixels)
          if (img.width === 200 && img.height === 200) {
            setLogoFiles && setLogoFiles(fileList)
          } else {
            message.error('Image must be exactly 200x200 pixels!')
          }
        }
      }
    },
    onRemove() {
      setLogoFiles && setLogoFiles([])
    },
  }

  useEffect(() => {
    getActiveHealthPartnerCategoryPagination()
  }, [getActiveHealthPartnerCategoryPagination])

  useEffect(() => {
    if (categoryData) {
      const options = mapSelectOptions(
        categoryData.backofficeGetActiveHealthPartnerCategoryPagination.data,
        'displayNameEn',
        'id',
      )
      setCategoryOptions(options)
    }
  }, [categoryData])

  return { categoryOptions, uploadProps }
}

export default WellBeingPartnerInfoHook
