export enum ReturnTimeTopicEnum {
  NORMAL = 'normal',
  TRAFFIC_NO_PREMIUM = 'traffic_no_premium',
}

export const TVI_MODE = {
  TVICONNECT: 'TVI connect',
  MANUAL: 'Manual',
  SCHEDULE: 'Schedule',
}

export enum TviStatusEnum {
  DRIVING = 'DRIVING',
  IDLE = 'IDLE',
  STANDBY = 'STANDBY',
  TRAFFIC = 'TRAFFIC',
  CHARGING = 'CHARGING',
  NO_SIGNAL = 'NO_SIGNAL',
  NOT_IN_USE = 'NOT IN USE',
}

export const TVI_STATUS_COLORS: Record<TviStatusEnum, string> = {
  [TviStatusEnum.DRIVING]: '#20B857',
  [TviStatusEnum.IDLE]: '#FF8F00',
  [TviStatusEnum.STANDBY]: '#0290FF',
  [TviStatusEnum.TRAFFIC]: '#FF3511',
  [TviStatusEnum.CHARGING]: '#64FFDA',
  [TviStatusEnum.NO_SIGNAL]: '#5925DC',
  [TviStatusEnum.NOT_IN_USE]: '#666666',
}

export const TVI_STATUS_TEXTS: Record<TviStatusEnum, string> = {
  [TviStatusEnum.DRIVING]: 'Driving',
  [TviStatusEnum.IDLE]: 'Idle',
  [TviStatusEnum.STANDBY]: 'Standby',
  [TviStatusEnum.TRAFFIC]: 'Traffic',
  [TviStatusEnum.CHARGING]: 'Charging',
  [TviStatusEnum.NO_SIGNAL]: 'No-Signal',
  [TviStatusEnum.NOT_IN_USE]: 'Not in use',
}

export const HISTORY_CAR_USAGE_PAGINATION_LIMIT = 15
