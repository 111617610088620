import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'

import WellBeingBranchTable from './WellBeingBranchTable'
import WellBeingPartnerStatus from './WellBeingPartnerStatus'
import WellBeingPartnerPageHook from './WellBeingPartnerPage.hook'
import WellBeingPartnerInfo from './WellBeingPartnerInfo'
import { WellBeingModeEnum } from '@/constant/WELL_BEING'
import {
  ActionBlock,
  ButtonAction,
  CreatedByText,
  CreatedByTextValue,
  Form,
  Header,
  Title,
} from './styles'

const WellBeingPartnerPage = () => {
  const {
    form,
    partnerId,
    partnerData,
    logoPartnerFiles,
    isButtonDisabled,
    isMutationUpdateLoading,
    partnerLoading,
    isLoading,
    handleFormComplete,
    setLogoPartnerFiles,
    onSubmit,
  } = WellBeingPartnerPageHook()
  const location = useLocation()

  const displayMode = location?.state?.action || WellBeingModeEnum.VIEW
  const isEditMode = displayMode === WellBeingModeEnum.EDIT
  const textTitle = isEditMode ? 'แก้ไขพาร์ทเนอร์' : 'ข้อมูลพาร์ทเนอร์และสาขา'
  const partnerInfo = partnerData?.backofficeGetActiveHealthPartnerById

  return (
    <>
      <Header>
        <Title>{textTitle}</Title>
        {isEditMode && (
          <ActionBlock>
            <ButtonAction
              type="primary"
              data-testid="well-being-branch-update"
              loading={isLoading || isMutationUpdateLoading}
              disabled={isButtonDisabled}
              onClick={onSubmit}
            >
              บันทึก
            </ButtonAction>
          </ActionBlock>
        )}
      </Header>
      <CreatedByText>
        สร้างโดย<CreatedByTextValue>{partnerInfo?.createdByText}</CreatedByTextValue>
        อัพเดตข้อมูลล่าสุด
        <CreatedByTextValue>
          {partnerInfo?.updatedByText}
          {'  '}
          {dayjs(partnerInfo?.updatedAt).format('DD-MM-YYYY')}
        </CreatedByTextValue>
      </CreatedByText>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        data-testid="well-being-partner-form"
        onFieldsChange={handleFormComplete}
      >
        {!partnerLoading && (
          <WellBeingPartnerInfo
            displayMode={displayMode}
            originLogoUrl={partnerData?.backofficeGetActiveHealthPartnerById?.logoImageUrl}
            logoFiles={logoPartnerFiles}
            setLogoFiles={setLogoPartnerFiles}
          />
        )}
        <WellBeingPartnerStatus displayMode={displayMode} />
      </Form>
      {partnerId && <WellBeingBranchTable partnerId={partnerId} displayMode={displayMode} />}
    </>
  )
}

export default WellBeingPartnerPage
