import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import { useQuery } from '@tanstack/react-query'
import { CreateTempPasswordResponseType } from '@/services/tempPassword/useCreateTempPassword/type'

const fetchCreateTempPassword = async (userId: string, reason: string) => {
  const data = { userId, reason }
  const response = await httpClientPrivate.post<CreateTempPasswordResponseType>(
    `${config.restUrl}/v1/backoffice/user/temp-password/create`,
    data,
  )
  return response.data
}

const useCreateTempPassword = (query: { userId: string; reason: string }) => {
  const queryKey = ['useCreateTempPassword', query]
  const queryFn = async () => fetchCreateTempPassword(query.userId, query.reason)
  const queryResult = useQuery({ queryFn, queryKey, enabled: !!query.userId })
  return { ...queryResult }
}

export { fetchCreateTempPassword, useCreateTempPassword }
