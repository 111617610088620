import { TVI_STATUS_COLORS, TviStatusEnum } from '@/constant/RETURN_TIME'
import { Geometry, Feature } from 'geojson'

function HistoryCarUsageMapHook() {
  const getColorByTviStatus = (tviStatus: string): string =>
    TVI_STATUS_COLORS[tviStatus as TviStatusEnum] || '#0000FF'

  const style = (feature: Feature<Geometry, any>) => {
    return {
      color: getColorByTviStatus(feature.properties.tviStatus),
      weight: 7,
      opacity: 1,
    }
  }

  return {
    style,
  }
}

export default HistoryCarUsageMapHook
