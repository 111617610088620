import styled from '@emotion/styled'
import { Input as AntdInput } from 'antd'

export const Title = styled('h4')`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
`

export const Subtitle = styled('div')`
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
`

export const TextArea = styled(AntdInput.TextArea)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  min-height: 104px !important;
`

export const Input = styled(AntdInput)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 8px;
`
