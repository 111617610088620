import { useCallback, useEffect, useState } from 'react'
import { Form, UploadFile, UploadProps } from 'antd'
import _ from 'lodash'

import { CreateActiveHealthPartnerInput } from '@/gql/activeHealthPartner/backofficeCreateActiveHealthPartner/interface'
import { usePartnerLogoUpload } from '@/services/wellBeing/usePartnerLogoUpload'
import { mapSelectOptions } from '@/libs/utils/map-select-options.util'
import { useLazyQuery, useMutation } from '@apollo/client'
import { activeHealthPartnerGql } from '@/gql'
import message from '@/libs/message'

const WellBeingPartnerModalHook = ({
  handleOk,
  handleCancel,
}: {
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [logoPartnerFiles, setLogoPartnerFiles] = useState<UploadFile[]>([])

  // Category dropdown options
  const [categoryOptions, setCategoryOptions] = useState<
    { label: string; value: string }[] | undefined
  >([])

  // Fetch category data
  const [backofficeGetActiveHealthPartnerCategoryPagination, { data: categoryData }] = useLazyQuery(
    activeHealthPartnerGql.query.backofficeGetActiveHealthPartnerCategoryPagination,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const getActiveHealthPartnerCategoryPagination = useCallback(() => {
    backofficeGetActiveHealthPartnerCategoryPagination({
      variables: {
        input: {},
      },
    })
  }, [backofficeGetActiveHealthPartnerCategoryPagination])

  // Upload props for Antd Upload component
  const uploadProps: UploadProps = {
    fileList: logoPartnerFiles,
    maxCount: 1,
    accept: '.png,.jpg,.jpeg',
    beforeUpload: () => false,
    onChange({ file, fileList }) {
      if (fileList.length < 1) return

      // Validate file size (must be less than 100KB)
      const isLt100KB = file.size && file.size / 1024 < 100
      if (!isLt100KB) {
        message.error('File must smaller than 100KB!')
        return
      }

      const reader = new FileReader()

      // `file` is of type `UploadFile<any>` from Ant Design, but readAsDataURL() requires a Blob or File.
      reader.readAsDataURL(file as unknown as Blob)
      reader.onload = (event) => {
        const img = new Image()
        img.src = event.target?.result as string
        img.onload = () => {
          // Validate image dimensions (must be exactly 200x200 pixels)
          if (img.width === 200 && img.height === 200) {
            setLogoPartnerFiles(fileList)
          } else {
            message.error('Image must be exactly 200x200 pixels!')
          }
        }
      }
    },
    onRemove() {
      setLogoPartnerFiles([])
    },
  }

  // Create partner logo
  const { mutateAsync: uploadLogo } = usePartnerLogoUpload()

  // Function to handle logo upload
  const handleUploadLogo = async (): Promise<string | undefined> => {
    if (!logoPartnerFiles.length) return

    // Update file status to "uploading"
    setLogoPartnerFiles((files) =>
      files.map((upload) => ({ ...upload, percent: 60, status: 'uploading' })),
    )

    // Upload the first file and return the uploaded URL
    const response = await uploadLogo(logoPartnerFiles[0].originFileObj as File)
    return response?.url
  }

  // Use GraphQL mutation for creating partner data
  const [mutationCreatePartner, { loading: isMutationCreateLoading }] = useMutation(
    activeHealthPartnerGql.mutation.backofficeCreateActiveHealthPartner,
    {
      fetchPolicy: 'no-cache',
    },
  )

  // Function to create partner details
  const createPartner = async (formValues: any, logoImageUrl?: string) => {
    try {
      const { displayNameTh, displayNameEn, categoryId } = formValues
      const input: CreateActiveHealthPartnerInput = {
        categoryId,
        displayNameTh,
        displayNameEn,
        logoImageUrl,
      }

      await mutationCreatePartner({ variables: { input } })
      message.success({ content: 'เพิ่มพาร์ทเนอร์สำเร็จ', duration: 10 })
    } catch (error: any) {
      message.error(error?.message || 'กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง')
    }
  }

  // Handle create partner
  const handleCreatePartner = async (): Promise<void> => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      // Upload logo if available
      const logoUrl = logoPartnerFiles.length > 0 ? await handleUploadLogo() : undefined

      // Create partner
      if (logoUrl && !_.isEmpty(values)) {
        await createPartner(values, logoUrl)
      }

      handleOk?.()
    } catch (error: any) {
      message.error({ content: error?.response?.data?.message || 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง' })
    } finally {
      setLoading(false)
      resetForm()
    }
  }

  // Reset form fields and states
  const resetForm = () => {
    form.resetFields()
    setIsButtonDisabled(true)
    setLogoPartnerFiles([])
  }

  // Validate form completion
  const handleFormComplete = () => {
    const values = form.getFieldsValue()
    const isEmpty = Object.values(values || []).some((item: any) => item == null)
    const isLogoUpdated = logoPartnerFiles?.length > 0

    setIsButtonDisabled(isEmpty || !isLogoUpdated)
  }

  // Form submit handler
  const onSubmit = (): void => {
    handleCreatePartner()
  }

  // Close modal and reset form
  const onClose = (): void => {
    resetForm()
    handleCancel?.()
  }

  useEffect(() => {
    handleFormComplete()
  }, [logoPartnerFiles])

  useEffect(() => {
    getActiveHealthPartnerCategoryPagination()
  }, [getActiveHealthPartnerCategoryPagination])

  useEffect(() => {
    if (categoryData) {
      const options = mapSelectOptions(
        categoryData.backofficeGetActiveHealthPartnerCategoryPagination.data,
        'displayNameEn',
        'id',
      )
      setCategoryOptions(options)
    }
  }, [categoryData])

  return {
    form,
    isLoading,
    isButtonDisabled,
    isMutationCreateLoading,
    categoryOptions,
    uploadProps,
    handleFormComplete,
    onSubmit,
    onClose,
  }
}

export default WellBeingPartnerModalHook
