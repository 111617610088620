import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeUpdateActiveHealthPartnerDataInterface,
  BackofficeUpdateActiveHealthPartnerInputInterface,
} from './interface'

const backofficeUpdateActiveHealthPartner: TypedDocumentNode<
  BackofficeUpdateActiveHealthPartnerDataInterface,
  BackofficeUpdateActiveHealthPartnerInputInterface
> = gql`
  mutation BackofficeUpdateActiveHealthPartner($input: UpdateActiveHealthPartnerInput!) {
    backofficeUpdateActiveHealthPartner(input: $input) {
      id
      displayNameTh
      displayNameEn
    }
  }
`

export default backofficeUpdateActiveHealthPartner
