export enum WellBeingModeEnum {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum WellBeingModalActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
}

export const WELL_BEING_TITLE = {
  [WellBeingModalActionEnum.CREATE]: 'เพิ่มสาขา',
  [WellBeingModalActionEnum.UPDATE]: 'แก้ไขสาขา',
}
