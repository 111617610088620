import { useRecoilState, useRecoilValue } from 'recoil'
import { useLocation } from 'react-router-dom'
import { TabsProps } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import policyAtom from '@/recoil/policy'
import UserInfoTab from '@/components/feature/PolicyDetailPage/UserInfoTab'
import PremiumTab from '@/components/feature/PolicyDetailPage/PremiumTab'
import ActivityTab from '@/components/feature/PolicyDetailPage/ActivityTab'
import DeviceTab from '@/components/feature/PolicyDetailPage/DeviceTab'
import { validatePerm } from '@/libs/validatePermission'
import { BACKOFFICE_USER_PAGE, BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import userAtom from '@/recoil/user'
import { ActiveHealthPolicyMenuEnum } from '@/constant/ACTIVE_HEALTH_POLICY_MENU'
import { useLazyQuery } from '@apollo/client'
import { userGql } from '@/gql'
import { toThaiLocaleDateString } from '@/libs/date'
import { UserPolicyInterface } from '@/gql/ppuMotor/getAllUserPoliciesByIdentityId/interfaces'
import { PolicyGroupEnum } from '@/constant/POLICY_GROUP'
import DeviceDetailHook from '@/components/feature/PolicyDetailPage/PolicyCard/DeviceDetail.hook'
import CONSTANT from '@/constant'
import { SearchStateEnum } from '@/components/feature/PolicyDetailPage/SearchPolicyBox/SearchPolicyBox.hook'
import policyDetailAtom from '@/recoil/policyDetail'
import { PolicyMenuEnum } from '@/constant/POLICY_MENU'
import HistoryCarUsage from './HistoryCarUsage'
import ReturnTimeTable from '../ReturnTimePage/ReturnTimeTable/ReturnTimeTable'

function PolicyDetailPageHook() {
  const location = useLocation()
  const state = location.state as { referrer?: string; policyNumber?: string }
  const { handleFetchDeviceDetail } = DeviceDetailHook()
  const [policy, setPolicy] = useRecoilState(policyAtom)
  const policyDetail = useRecoilValue(policyDetailAtom)
  const user = useRecoilValue(userAtom)
  const [tabActiveKey, setTabActiveKey] = useState<ActiveHealthPolicyMenuEnum | undefined>(
    undefined,
  )
  const [searchState, setSearchState] = useState<SearchStateEnum>(SearchStateEnum.NONE)
  const [selectedCoverageId, setSelectCoverageId] = useState<string>('')
  const isMotorOnOff =
    policy.policyGroup === PolicyGroupEnum.ON_OFF_PACKAGE ||
    policy.policyGroup === PolicyGroupEnum.ON_OFF_TOP_UP
  const healthTabItems: TabsProps['items'] = [
    {
      key: ActiveHealthPolicyMenuEnum.PERSONAL,
      label: 'ข้อมูลผู้เอาประกันภัย',
      children: <UserInfoTab />,
    },
    {
      key: ActiveHealthPolicyMenuEnum.PREMIUM,
      label: 'ค่าเบี้ย',
      children: <PremiumTab />,
      disabled: !validatePerm([BACKOFFICE_USER_PERMISSION.HLT010101], user.permissions),
    },
    {
      key: ActiveHealthPolicyMenuEnum.ACTIVITY,
      label: 'ข้อมูลการออกกำลังกาย',
      children: <ActivityTab />,
      disabled: !validatePerm([BACKOFFICE_USER_PERMISSION.HLT010105], user.permissions),
    },
    {
      key: ActiveHealthPolicyMenuEnum.DEVICE,
      label: 'อุปกรณ์',
      children: <DeviceTab />,
    },
  ]
  const tabItems: TabsProps['items'] = [
    {
      key: PolicyMenuEnum.PERSONAL,
      label: 'ข้อมูลผู้เอาประกันภัย',
      children: <UserInfoTab />,
      page: BACKOFFICE_USER_PAGE.MTP0101,
      display: true,
    },
    {
      key: PolicyMenuEnum.HISTORY_CAR_USAGE,
      label: 'ประวัติการใช้รถ',
      children: <HistoryCarUsage />,
      page: BACKOFFICE_USER_PAGE.MTP0102,
      display: isMotorOnOff,
    },
    {
      key: PolicyMenuEnum.RETURN_COVERAGE_HOUR,
      label: 'คืนชั่วโมงความคุ้มครอง',
      children: <ReturnTimeTable policyNumber={policy.policyNumber} />,
      page: BACKOFFICE_USER_PAGE.MTP0103,
      display: isMotorOnOff,
    },
  ]
    .filter((config) => validatePerm([config.page], user.pages) && config.display)
    .map(({ key, label, children }) => ({ key, label, children }))

  const [
    backofficeGetUserPolicyByPolicyNumber,
    { loading: backofficeGetUserPolicyByPolicyNumberLoading },
  ] = useLazyQuery(userGql.query.backofficeGetUserPolicyByPolicyNumber, {
    fetchPolicy: 'no-cache',
  })
  const isComponentLoading = backofficeGetUserPolicyByPolicyNumberLoading

  const handleSetTabActiveKey = (key?: ActiveHealthPolicyMenuEnum) => setTabActiveKey(key)

  // example result
  // input: 50000 seconds
  // output: 13:53
  const convertSecondsToHHmm = (seconds: number) => {
    if (!seconds) return 0

    const hrs = seconds / 3600
    const mins = (seconds % 3600) / 60
    const format = (value: number) => {
      if (Math.floor(value) < 9) return `0${Math.floor(value)}`

      return Math.floor(value)
    }

    return [hrs, mins].map(format).join(':')
  }

  const getPolicyByPolicyNumber = useCallback(
    (policyNumber: string) => {
      backofficeGetUserPolicyByPolicyNumber({ variables: { policyNumber } }).then(({ data }) => {
        if (data?.backofficeGetUserPolicyByPolicyNumber) {
          const dt = data.backofficeGetUserPolicyByPolicyNumber
          const policy = {
            policyNumber: dt.policyNumber,
            insuredName: dt.insuredName,
            planName: dt.planName || dt.campaignName || '-',
            campaignName: dt.campaignName,
            identityId: dt.identityId,
            insuredDateOfBirth: dt.insuredDateOfBirth
              ? toThaiLocaleDateString({ date: dt.insuredDateOfBirth })
              : '',
            insuredAddress: dt.insuredAddress,
            insuredDistrict: dt.insuredDistrict,
            insuredProvince: dt.insuredProvince,
            insuredPostCode: dt.insuredPostCode,
            deliveryAddress: dt.deliveryAddress || '-',
            beneficiary: dt.beneficiary || '-',
            policySuperAppStatus: dt.policySuperAppStatus,
            sumInsured: dt?.sumInsured?.toLocaleString() || '-',
            vehicleNumber: dt.vehicleNumber,
            effectiveDate: dt.effectiveDate
              ? toThaiLocaleDateString({ date: dt.effectiveDate })
              : '-',
            expiryDate: dt.expiryDate ? toThaiLocaleDateString({ date: dt.expiryDate }) : '-',
            garageGrade: dt.garageGrade || '-',
            compulsoryMotorInsurancePolicyNumber: dt.compulsoryMotorInsurancePolicyNumber || '-',
            ppuPlanType: dt.ppuPlanType,
            ppuTimeUsed: dt.ppuTimeUsed ? convertSecondsToHHmm(Number(dt.ppuTimeUsed)) : '-',
            ppuTimeLeft: dt.ppuTimeLeft ? convertSecondsToHHmm(Number(dt.ppuTimeLeft)) : '-',
            mtPolicyType: dt.mtPolicyType || '-',
            policyGroup: dt.policyGroup || '-',
            ipdSumInsured: dt.ipdSumInsured || '-',
            opdSumInsured: dt.opdSumInsured || '-',
            paMedicalExpenseLimit: dt.paMedicalExpenseLimit || '-',
            opdTotal: dt.opdTotal || '-',
            opdLeft: dt.opdLeft || '-',
            diseases: dt.diseases || [],
            roomLimit: dt.roomLimit || '-',
            paAccidentalDeath: dt.paAccidentalDeath || '-',
            taOnOffCoverDaysTotal: dt.taOnOffCoverDaysTotal || '-',
            taOnOffCoverDaysLeft: dt.taOnOffCoverDaysLeft || '-',
            carModelName: dt.carModelName || '-',
            carYear: dt.carYear || '',
            pvVehicleType: dt.pvVehicleType || '',
            additionalCoverages: dt.additionalCoverages || [],
          } as UserPolicyInterface

          setPolicy(policy)
          setSearchState(SearchStateEnum.FINISH)
        }
      })
    },
    [backofficeGetUserPolicyByPolicyNumber],
  )

  const handleVisiblePolicyDetail = (state: SearchStateEnum) => setSearchState(state)

  useEffect(() => {
    if (state?.referrer && state?.policyNumber) {
      const policyNumber = state.policyNumber
      const routeRef = state.referrer

      switch (routeRef) {
        case CONSTANT.ROUTES.POLICY_MANAGEMENT_PAGE:
          getPolicyByPolicyNumber(policyNumber)
          break
        case CONSTANT.ROUTES.DISCOUNT_ISSUE_PAGE:
          getPolicyByPolicyNumber(policyNumber)
          handleSetTabActiveKey(ActiveHealthPolicyMenuEnum.PREMIUM)
      }
    }
  }, [state])

  useEffect(() => {
    if (isComponentLoading) {
      setSearchState(SearchStateEnum.SEARCHING)
    }
  }, [isComponentLoading])

  useEffect(() => {
    if (
      policy.policyNumber &&
      policyDetail.id &&
      (policy.policyGroup === PolicyGroupEnum.ON_OFF_PACKAGE ||
        policy.policyGroup === PolicyGroupEnum.ON_OFF_TOP_UP)
    ) {
      handleFetchDeviceDetail({
        userId: policyDetail.id,
        policyNumber: policy.policyNumber,
      })
    }
  }, [policy, policyDetail])

  return {
    tabItems,
    healthTabItems,
    user,
    policy,
    tabActiveKey,
    handleSetTabActiveKey,
    getPolicyByPolicyNumber,
    isComponentLoading,
    searchState,
    handleVisiblePolicyDetail,
    selectedCoverageId,
    setSelectCoverageId,
  }
}

export default PolicyDetailPageHook
