import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeCreateActiveHealthPartnerDataInterface,
  BackofficeCreateActiveHealthPartnerInputInterface,
} from './interface'

const backofficeCreateActiveHealthPartner: TypedDocumentNode<
  BackofficeCreateActiveHealthPartnerDataInterface,
  BackofficeCreateActiveHealthPartnerInputInterface
> = gql`
  mutation BackofficeCreateActiveHealthPartner($input: CreateActiveHealthPartnerInput!) {
    backofficeCreateActiveHealthPartner(input: $input) {
      id
      displayNameTh
      displayNameEn
      logoImageUrl
    }
  }
`

export default backofficeCreateActiveHealthPartner
