import { useMutation } from '@tanstack/react-query'
import { httpClientPrivate } from '@/libs/httpClient'
import config from '@/config'

const uploadActiveHealthPartnerLogo = async (file: File, partnerId?: string) => {
  const formData = new FormData()
  formData.append('file', file)
  partnerId && formData.append('partnerId', partnerId)

  const response = await httpClientPrivate.post(
    `${config.restUrl}/v1/active-health-partner/logo/upload`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  )

  return response.data
}

const usePartnerLogoUpload = (partnerId?: string) => {
  const mutation = useMutation({
    mutationKey: ['usePartnerLogoUpload', partnerId],
    mutationFn: async (file: File) => uploadActiveHealthPartnerLogo(file, partnerId),
  })

  return mutation
}

export { usePartnerLogoUpload }
