import styled from '@emotion/styled'
import { Breadcrumb as AntBreadcrumb } from 'antd'

import { ContainerProps } from './types'

export const Container = styled('div')<ContainerProps>`
  width: 100%;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  justify-content: space-between;
  align-items: center;
`
export const Breadcrumb = styled(AntBreadcrumb)``
export const Item = styled('span')`
  cursor: pointer;

  &:hover {
    color: #009640;
  }
`

export const SubContainer = styled('div')`
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Block = styled('div')`
  display: flex;
  align-items: center;
`

export const Title = styled('p')`
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  margin-bottom: 0;
  margin-right: 10px;
`

export const InformationGroup = styled('div')``

export const InformationTitle = styled('span')`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  margin-right: 5px;
  user-select: none;
`
export const Information = styled('span')`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  cursor: pointer;
`
