import dayjs from 'dayjs'
import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { RangePickerProps } from 'antd/es/date-picker'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import _ from 'lodash'

import { ActiveHealthPartnerBranchWithScanCount } from '@/gql/activeHealthPartner/backofficeGetActiveHealthPartnerBranchPaginationWithScanCount/interface'
import { CreateActiveHealthPartnerBranchInput } from '@/gql/activeHealthPartnerBranch/backofficeCreateActiveHealthPartnerBranch/interface'
import { activeHealthPartnerBranchGql, activeHealthPartnerGql, addressGql } from '@/gql'
import { useSubDistrict } from '@/services/address/useSubDistrict'
import { useDistrict } from '@/services/address/useDistrict'
import { useProvince } from '@/services/address/useProvince'
import message from '@/libs/message'

import { UpdateActiveHealthPartnerBranchInput } from '@/gql/activeHealthPartnerBranch/backofficeUpdateActiveHealthPartnerBranch/interface'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { mapSelectOptions } from '@/libs/utils/map-select-options.util'
import { WellBeingModalActionEnum } from '@/constant/WELL_BEING'
import { validatePerm } from '@/libs/validatePermission'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const WellBeingBranchModalHook = ({
  partnerId,
  isModalOpen,
  action,
  data,
  handleOk,
  handleCancel,
}: {
  partnerId: string
  isModalOpen: boolean
  action: WellBeingModalActionEnum
  data?: ActiveHealthPartnerBranchWithScanCount
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const user = useRecoilValue(userAtom)
  const isDisableQrCode =
    action === WellBeingModalActionEnum.UPDATE &&
    !validatePerm([BACKOFFICE_USER_PERMISSION.WBE010105], user.permissions)

  const [form] = Form.useForm()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isDisabledDateRangePicker, setIsDisabledDateRangePicker] = useState<
    boolean | [boolean, boolean]
  >(false)

  const [isDisableDistrict, setDisableDistrict] = useState<boolean>(true)
  const [isDisableSubDistrict, setDisableSubDistrict] = useState<boolean>(true)
  const [isDisablePostCode, setDisablePostCode] = useState<boolean>(true)

  // State for address dropdowns
  const [provinceCode, setProvinceCode] = useState<string | undefined>()
  const [districtCode, setDistrictCode] = useState<string | undefined>()
  const [postCodeOptions, setPostCodeOptions] = useState<
    { label: string; value: string }[] | undefined
  >([])

  // Fetch address data
  const { data: provinces } = useProvince()
  const { data: districts } = useDistrict(provinceCode || '')
  const { data: subDistricts } = useSubDistrict(districtCode || '')

  const provinceOptions = mapSelectOptions(provinces, 'displayNameTh', 'provinceCode')
  const districtOptions = mapSelectOptions(districts, 'displayNameTh', 'districtCode')
  const subDistrictOptions = mapSelectOptions(subDistricts, 'displayNameTh', 'subDistrictCode')

  const [getAddress] = useLazyQuery(addressGql.query.backofficeGetAddresses, {
    fetchPolicy: 'no-cache',
  })
  const [fetchBonusCriteria, { data: bonusCriteriaData, loading: isBonusCriteriaLoading }] =
    useLazyQuery(activeHealthPartnerGql.query.backofficeGetRecentActiveHealthBonusCriteria, {
      fetchPolicy: 'no-cache',
    })
  const [mutationCreateBranch, { loading: isMutationCreateLoading }] = useMutation(
    activeHealthPartnerBranchGql.mutation.backofficeCreateActiveHealthPartnerBranch,
    {
      fetchPolicy: 'no-cache',
    },
  )
  const [mutationUpdateBranch, { loading: isMutationUpdateLoading }] = useMutation(
    activeHealthPartnerBranchGql.mutation.backofficeUpdateActiveHealthPartnerBranch,
    {
      fetchPolicy: 'no-cache',
    },
  )

  // Reset dependent fields when province changes
  const resetFieldsOnProvinceChange = (provinceCode: string | undefined) => {
    if (provinceCode) {
      form.setFieldsValue({
        districtCode: undefined,
        subDistrictCode: undefined,
        postCode: undefined,
      })
      setProvinceCode(provinceCode)
      setDistrictCode(undefined)
      setDisableDistrict(false)
      setDisableSubDistrict(true)
      setDisablePostCode(true)
    }
  }

  // Reset dependent fields when district changes
  const resetFieldsOnDistrictChange = (districtCode: string | undefined) => {
    if (districtCode) {
      form.setFieldsValue({ subDistrictCode: undefined, postCode: undefined })
      setDistrictCode(districtCode)
      setDisableSubDistrict(false)
      setDisablePostCode(true)
    }
  }

  // Update postal code based on selected sub-district
  const handleSubDistrictChange = (subDistrictCode: string | undefined) => {
    if (subDistrictCode) {
      const postCode = subDistricts?.find((sd) => sd.subDistrictCode === subDistrictCode)?.postCode
      setPostCodeOptions(postCode ? [{ label: postCode, value: postCode }] : [])
      form.setFieldsValue({ postCode })
      setDisablePostCode(false)
    }
  }

  // Handle form changes for location selection
  const handleFormChange = (allValues: any) => {
    const { provinceCode, districtCode, subDistrictCode } = allValues

    resetFieldsOnProvinceChange(provinceCode)
    resetFieldsOnDistrictChange(districtCode)
    handleSubDistrictChange(subDistrictCode)
  }

  const getContacts = (contacts: Array<any>) => {
    return (contacts || []).map((contact) => ({
      id: contact?.id || undefined,
      phoneNumber: contact?.phoneNumber,
      extensionNumber: contact?.extensionNumber || undefined,
    }))
  }

  const getBranchData = () => ({
    displayNameTh: data?.activeHealthPartnerBranch.displayNameTh,
    displayNameEn: data?.activeHealthPartnerBranch.displayNameEn,
    addressTh: data?.activeHealthPartnerBranch.addressTh,
    addressEn: data?.activeHealthPartnerBranch.addressEn,
    lat: data?.activeHealthPartnerBranch.lat,
    long: data?.activeHealthPartnerBranch.long,
    subDistrictCode: data?.activeHealthPartnerBranch.subDistrict.subDistrictCode,
    contacts: getContacts(
      data?.activeHealthPartnerBranch?.activeHealthPartnerBranchContacts as Array<any>,
    ),
    startDate: data?.activeHealthPartnerBranch.startDate,
    endDate: data?.activeHealthPartnerBranch.endDate,
    isActiveQr: data?.activeHealthPartnerBranch.qr.isActive,
  })

  const handleFormComplete = () => {
    const values = form.getFieldsValue()
    const isEmpty = Object.values(values || []).some((item: any) => item == null)

    if (action === WellBeingModalActionEnum.UPDATE) {
      const {
        displayNameTh,
        displayNameEn,
        addressTh,
        addressEn,
        subDistrictCode,
        lat,
        long,
        contacts,
        dateRang,
        isActiveQr,
      } = values
      const input = {
        displayNameTh,
        displayNameEn,
        addressTh,
        addressEn,
        lat: Number(lat),
        long: Number(long),
        subDistrictCode,
        contacts: getContacts(contacts),
        startDate: dayjs(dateRang[0]).toISOString(),
        endDate: dayjs(dateRang[1]).toISOString(),
        isActiveQr,
      }
      const isSame = _.isEqual(input, getBranchData())
      setIsButtonDisabled(isEmpty || isSame)
    } else {
      setIsButtonDisabled(isEmpty)
    }
  }

  const createBranch = async (formValues: any) => {
    const {
      displayNameTh,
      displayNameEn,
      addressTh,
      addressEn,
      subDistrictCode,
      lat,
      long,
      contacts,
      dateRang,
      isActiveQr,
    } = formValues
    const input: CreateActiveHealthPartnerBranchInput = {
      partnerId,
      displayNameTh,
      displayNameEn,
      addressTh,
      addressEn,
      lat: Number(lat),
      long: Number(long),
      subDistrictCode,
      contacts,
      startDate: dayjs(dateRang[0]).startOf('day').format('YYYY-MM-DD'),
      endDate: dayjs(dateRang[1]).startOf('day').format('YYYY-MM-DD'),
      isActiveQr,
    }

    try {
      await mutationCreateBranch({ variables: { input } })
      handleOk && handleOk()
      message.success({ content: 'เพิ่มสาขาสำเร็จ', duration: 10 })
      onClose()
    } catch (error: any) {
      message.error(error?.message || 'กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง')
    }
  }

  const updateBranch = async (id: string, formValues: any) => {
    const {
      displayNameTh,
      displayNameEn,
      addressTh,
      addressEn,
      subDistrictCode,
      lat,
      long,
      contacts,
      dateRang,
      isActiveQr,
    } = formValues
    const input: UpdateActiveHealthPartnerBranchInput = {
      id,
      displayNameTh,
      displayNameEn,
      addressTh,
      addressEn,
      lat: Number(lat),
      long: Number(long),
      subDistrictCode,
      contacts,
      startDate: dayjs(dateRang[0]).startOf('day').format('YYYY-MM-DD'),
      endDate: dayjs(dateRang[1]).startOf('day').format('YYYY-MM-DD'),
      isActiveQr,
    }

    try {
      await mutationUpdateBranch({ variables: { input } })
      handleOk && handleOk()
      message.success({ content: 'แก้ไขสาขาสำเร็จ', duration: 10 })
      onClose()
    } catch (error: any) {
      message.error(error?.message || 'กรุณาตรวจสอบข้อมูลและลองใหม่อีกครั้ง')
    }
  }

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true)
        if (values) {
          if (action === WellBeingModalActionEnum.CREATE) {
            await createBranch(values)
          }

          if (action === WellBeingModalActionEnum.UPDATE && data?.activeHealthPartnerBranch.id) {
            await updateBranch(data?.activeHealthPartnerBranch.id, values)
          }
        }
      })
      .catch(() => {
        message.error({ content: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onClose = (): void => {
    form.resetFields()
    setIsButtonDisabled(true)
    handleCancel && handleCancel()
  }

  const disabledDatePickerRangePicker: RangePickerProps['disabledDate'] = (current) => {
    if (!bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria) return true

    const startCampaign = dayjs(
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.startDate,
    )
    const endCampaign = dayjs(
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria.endDate,
    )

    return (
      current < dayjs().startOf('day') ||
      current.isBefore(startCampaign, 'day') ||
      current.isAfter(endCampaign, 'day')
    )
  }

  const validateLatitude = (_: any, data: string) => {
    const value = Number(data)
    return !isNaN(value) && value >= -90 && value <= 90
      ? Promise.resolve()
      : Promise.reject(new Error('ละติจูดต้องเป็นตัวเลขระหว่าง -90 ถึง 90'))
  }

  const validateLongitude = (_: any, data: string) => {
    const value = Number(data)
    return !isNaN(value) && value >= -180 && value <= 180
      ? Promise.resolve()
      : Promise.reject(new Error('ลองจิจูดต้องเป็นตัวเลขระหว่าง -180 ถึง 180'))
  }

  const getRecentBonusCriteria = useCallback(() => {
    fetchBonusCriteria()
  }, [fetchBonusCriteria])

  useEffect(() => {
    getRecentBonusCriteria()
  }, [])

  const setFieldsAddress = async (subDistrictCode: string) => {
    try {
      const address = await getAddress({
        variables: { input: { subDistrictCode } },
      })

      if (address.data?.backofficeGetAddresses) {
        const province = address.data.backofficeGetAddresses[0]
        const districts = province.districts[0]
        const subDistricts = districts.subDistricts[0]
        const input = {
          provinceCode: province.provinceCode,
          districtCode: districts.districtCode,
          subDistrictCode: subDistricts.subDistrictCode,
          postCode: subDistricts.postCode,
        }
        handleFormChange(input)
        form.setFieldsValue(input)
      }
    } catch (error) {
      console.error('Error fetching address:', error)
    }
  }

  useEffect(() => {
    if (action === WellBeingModalActionEnum.UPDATE && data) {
      form.setFieldsValue({
        displayNameTh: data.activeHealthPartnerBranch.displayNameTh,
        displayNameEn: data.activeHealthPartnerBranch.displayNameEn,
        addressTh: data.activeHealthPartnerBranch.addressTh,
        addressEn: data.activeHealthPartnerBranch.addressEn,
        lat: data.activeHealthPartnerBranch.lat,
        long: data.activeHealthPartnerBranch.long,
        contacts: data.activeHealthPartnerBranch.activeHealthPartnerBranchContacts?.map(
          ({ id, phoneNumber, extensionNumber }) => ({ id, phoneNumber, extensionNumber }),
        ),
        isActiveQr: data.activeHealthPartnerBranch.qr.isActive,
      })

      setFieldsAddress(data.activeHealthPartnerBranch.subDistrict.subDistrictCode)

      const startCampaign = dayjs(data.activeHealthPartnerBranch.startDate).startOf('day')
      const endCampaign = dayjs(data.activeHealthPartnerBranch.endDate)

      const startOfDay = dayjs().startOf('day')
      const isBeforeBonusEnd = startCampaign.isBefore(startOfDay)

      setIsDisabledDateRangePicker(isBeforeBonusEnd ? [true, false] : false)
      form.setFieldValue('dateRang', [startCampaign, endCampaign])
    }
  }, [data, action])

  useEffect(() => {
    const { startDate, endDate } =
      bonusCriteriaData?.backofficeGetRecentActiveHealthBonusCriteria || {}
    if (bonusCriteriaData && !form.getFieldValue('dateRang') && startDate && endDate) {
      const startOfDay = dayjs().startOf('day')
      const startCampaign = dayjs(startDate).isAfter(startOfDay) ? dayjs(startDate) : startOfDay
      const endCampaign = dayjs(endDate)

      form.setFieldValue('dateRang', [startCampaign, endCampaign])
      setIsDisabledDateRangePicker(false)
    }
  }, [bonusCriteriaData, form, isModalOpen])

  return {
    form,
    isLoading,
    isBonusCriteriaLoading,
    isMutationCreateLoading,
    isMutationUpdateLoading,
    isDisableQrCode,
    isDisableDistrict,
    isDisableSubDistrict,
    isDisablePostCode,
    isDisabledDateRangePicker,
    isButtonDisabled,
    districtOptions,
    provinceOptions,
    subDistrictOptions,
    postCodeOptions,
    disabledDatePickerRangePicker,
    validateLatitude,
    validateLongitude,
    handleFormChange,
    handleFormComplete,
    onSubmit,
    onClose,
  }
}

export default WellBeingBranchModalHook
