import {
  Button,
  Container,
  Form,
  FormItem,
  IconSvg,
  IconWrapper,
  Input,
  Select,
  Upload,
  UploadWrapper,
  UploadIcon,
  UploadTextWrapper,
  UploadText,
  UploadTextDetail,
} from './styles'
import WellBeingPartnerModalHook from './WellBeingPartnerModal.hook'
import Modal from '@/components/common/Modal'
import { IMAGE_URL } from '@/constant/IMAGE'

const WellBeingPartnerModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
}: {
  isModalOpen: boolean
  handleOk?: () => void
  handleCancel?: () => void
}) => {
  const {
    form,
    isLoading,
    isButtonDisabled,
    categoryOptions,
    uploadProps,
    handleFormComplete,
    onSubmit,
    onClose,
  } = WellBeingPartnerModalHook({
    handleOk,
    handleCancel,
  })

  return (
    <Modal
      title="เพิ่มพาร์ทเนอร์"
      open={isModalOpen}
      width={500}
      data-testid="well-being-partner-modal"
      centered
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          data-testid="well-being-partner-modal-form-button-cancel"
          onClick={onClose}
        >
          ยกเลิก
        </Button>,
        <Button
          key="submit"
          type="primary"
          data-testid="well-being-partner-modal-form-button-confirm"
          loading={isLoading}
          disabled={isButtonDisabled}
          onClick={onSubmit}
        >
          บันทึก
        </Button>,
      ]}
    >
      <Container>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          data-testid="well-being-partner-modal-form"
          onFieldsChange={handleFormComplete}
        >
          <FormItem label="โลโก้พาร์ทเนอร์*">
            <Upload
              listType="picture-card"
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
                removeIcon: (
                  <IconWrapper>
                    <IconSvg src={IMAGE_URL.deleteGreyIcon} />
                  </IconWrapper>
                ),
              }}
              {...uploadProps}
            >
              <UploadWrapper>
                <UploadIcon src={IMAGE_URL.uploadGreyIcon} />
                <UploadTextWrapper>
                  <UploadText>เลือกไฟล์</UploadText> หรือลากวาง
                </UploadTextWrapper>
                <UploadTextDetail>
                  ไฟล์ .jpg, .jpeg หรือ .png ไม่เกิน 100 K ขนาด 200x200 (1:1)
                </UploadTextDetail>
              </UploadWrapper>
            </Upload>
          </FormItem>

          <FormItem
            label="ชื่อพาร์ทเนอร์ (ไทย)*"
            name="displayNameTh"
            normalize={(value) => (value === '' ? undefined : value)}
          >
            <Input placeholder="ระบุ" data-testid="well-being-partner-modal-input-" />
          </FormItem>

          <FormItem
            label="ชื่อพาร์ทเนอร์ (อังกฤษ)*"
            name="displayNameEn"
            normalize={(value) => (value === '' ? undefined : value)}
          >
            <Input placeholder="ระบุ" data-testid="well-being-partner-modal-input-" />
          </FormItem>

          <FormItem
            label="หมวดหมู่ (Category)*"
            name="categoryId"
            normalize={(value) => (value === '' ? undefined : value)}
          >
            <Select placeholder="เลือกหมวดหมู่" options={categoryOptions} />
          </FormItem>
        </Form>
      </Container>
    </Modal>
  )
}

export default WellBeingPartnerModal
