import styled from '@emotion/styled'
import {
  Form as AntForm,
  Input as AntdInput,
  Select as AntdSelect,
  Upload as AntdUpload,
  Image as AntdImage,
} from 'antd'

export const Container = styled('div')`
  padding: 24px 32px 32px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin-top: 24px;
`

export const Box = styled('div')``

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`

export const Block = styled('div')`
  display: grid;
  grid-template-columns: auto 1fr;
`

export const PartnerLogo = styled('img')`
  width: 140px;
  height: 140px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
`

export const Input = styled(AntdInput)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 16px;
  border-radius: 8px;
  height: 40px;
`

export const FormItem = styled(AntForm.Item)`
  margin-bottom: 0px;

  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 500;
  }
`

export const FormGridBlock = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export const Select = styled(AntdSelect)`
  .ant-select-selector {
    height: 40px !important;
    padding: 5px 16px !important;
    border-radius: 8px !important;
  }

  .ant-select-selection-item {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 5px 0px !important;
  }

  .ant-select-selection-search {
    padding: 5px 0px !important;
  }
`

export const Upload = styled(AntdUpload)`
  .ant-upload-list-picture-card:has(.ant-upload-list-item-container) .ant-upload-select {
    display: none !important;
  }

  .ant-upload-select {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    width: 140px !important;
    height: 140px !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 15px !important;
    background: transparent !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    overflow: hidden !important;
  }

  .ant-upload-list-item-container {
    width: 140px !important;
    height: 140px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .ant-upload-list-item {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 15px !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;

    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out !important;
  }

  .ant-upload-list-item::before {
    background: rgba(0, 0, 0, 0.5) !important;
    width: 100% !important;
    height: 100% !important;
  }

  .ant-upload-list-item-actions {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .ant-progress-bg {
    background-color: #4caf50 !important; /* Green progress bar */
    border-radius: 10px;
  }
`

export const PreviewPartnerLogo = styled(AntdImage)`
  width: 100%;
  height: 100%;
`

export const HintUpload = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`

export const IconSvg = styled('img')`
  height: 20px;
  width: 20px;
`

export const IconWrapper = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 40px !important;
  height: 40px !important;
  background: rgba(255, 255, 255) !important;
  border-radius: 50% !important;
  color: white !important;
  cursor: pointer !important;
`
