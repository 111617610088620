import message from '../message'

const copyToClipboard = (text: string) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      message.success('คัดลอกข้อความสำเร็จ')
    })
    .catch(() => {
      message.error('คัดลอกข้อความไม่สำเร็จ')
    })
}

export default copyToClipboard
