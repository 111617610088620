import { gql, TypedDocumentNode } from '@apollo/client'
import {
  BackofficeGetActiveHealthPartnerCategoryPaginationDataInterface,
  BackofficeGetActiveHealthPartnerCategoryPaginationInputInterface,
} from './interface'

const backofficeGetActiveHealthPartnerCategoryPagination: TypedDocumentNode<
  BackofficeGetActiveHealthPartnerCategoryPaginationDataInterface,
  BackofficeGetActiveHealthPartnerCategoryPaginationInputInterface
> = gql`
  query BackofficeGetActiveHealthPartnerCategoryPagination($input: RequestPaginationInput!) {
    backofficeGetActiveHealthPartnerCategoryPagination(input: $input) {
      data {
        id
        displayNameTh
        displayNameEn
      }
      meta {
        itemsPerPage
        totalItems
        totalPages
        currentPage
        previousPage
        nextPage
        sortBy
        searchBy
        search
      }
    }
  }
`

export default backofficeGetActiveHealthPartnerCategoryPagination
