import { useMemo } from 'react'
import { Badge } from 'antd'
import {
  Container,
  Title,
  Subtitle,
  RadioGroup,
  Radio,
  RadioTitle,
  OnText,
  OffText,
  TopBlock,
  StatusBadge,
  TitleBlock,
  RowAntd,
  ColAntd,
  RadioSubTitle,
  FormItem,
} from './styles'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import { WellBeingModeEnum } from '@/constant/WELL_BEING'
import { validatePerm } from '@/libs/validatePermission'
import { useRecoilValue } from 'recoil'
import userAtom from '@/recoil/user'

const WellBeingPartnerStatus = ({ displayMode }: { displayMode: WellBeingModeEnum }) => {
  const user = useRecoilValue(userAtom)
  const isEditMode = useMemo(
    () =>
      displayMode === WellBeingModeEnum.EDIT &&
      validatePerm([BACKOFFICE_USER_PERMISSION.WBE010108], user.permissions),
    [displayMode, user.permissions],
  )

  const renderStatusBadge = () => (
    <FormItem shouldUpdate>
      {({ getFieldValue }) => {
        const isActive = getFieldValue('isActive')
        return (
          <StatusBadge isActive={isActive}>
            <Badge status={isActive ? 'success' : 'error'} />{' '}
            {isActive ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}
          </StatusBadge>
        )
      }}
    </FormItem>
  )

  return (
    <Container>
      <TopBlock isEditMode={isEditMode}>
        <TitleBlock>
          <Title>สถานะพาร์ทเนอร์</Title>
          {(displayMode === WellBeingModeEnum.VIEW || !isEditMode) && renderStatusBadge()}
        </TitleBlock>
        {isEditMode && (
          <Subtitle>
            คุณต้องการเปิดใช้งานพาร์ทเนอร์นี้ ให้แสดงบน Thaivivat App และสามารถแก้ไขข้อมูลในระบบ
            Backoffice
          </Subtitle>
        )}
      </TopBlock>

      <FormItem name="isActive" style={!isEditMode ? { display: 'none' } : undefined}>
        <RadioGroup>
          <RowAntd gutter={[8, 0]}>
            {[true, false].map((isActive) => (
              <ColAntd key={isActive.toString()} span={12}>
                <Radio value={isActive}>
                  <RadioTitle>
                    {isActive ? <OnText>เปิด</OnText> : <OffText>ปิด</OffText>} การใช้งานพาร์ทเนอร์
                  </RadioTitle>
                  <RadioSubTitle>
                    {isActive
                      ? 'แสดงพาร์ทเนอร์นี้บน Thaivivat App และลูกค้าสแกนรับคะแนนได้ ตามช่วงเวลาที่กำหนด'
                      : 'ไม่แสดงพาร์ทเนอร์นี้บน Thaivivat App และลูกค้าไม่สามารถสแกนได้'}
                  </RadioSubTitle>
                </Radio>
              </ColAntd>
            ))}
          </RowAntd>
        </RadioGroup>
      </FormItem>
    </Container>
  )
}

export default WellBeingPartnerStatus
