import { activeHealthPartnerGql } from '@/gql'
import { useLazyQuery } from '@apollo/client'
import { useCallback, useEffect } from 'react'

const CampaignPeriodHook = () => {
  const [
    backofficeGetRecentActiveHealthBonusCriteria,
    { data: recentBonustCriteriaData, loading: recentBonusCriteriaLoading },
  ] = useLazyQuery(activeHealthPartnerGql.query.backofficeGetRecentActiveHealthBonusCriteria, {
    fetchPolicy: 'no-cache',
  })

  const getRecentActiveHealthBonusCriteria = useCallback(() => {
    backofficeGetRecentActiveHealthBonusCriteria()
  }, [backofficeGetRecentActiveHealthBonusCriteria])

  useEffect(() => {
    getRecentActiveHealthBonusCriteria()
  }, [])

  return { recentBonustCriteriaData, recentBonusCriteriaLoading }
}

export default CampaignPeriodHook
