import styled from '@emotion/styled'
import { Radio as AntdRadio, Col, Row, Form as AntForm } from 'antd'
import { WellBeingModeEnum } from '@/constant/WELL_BEING'

export const Container = styled('div')`
  padding: 24px 32px 24px 32px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin-top: 24px;
`

export const Title = styled('div')`
  font-size: 20px;
  font-weight: 400;
  margin-right: 8px;
`

export const Subtitle = styled('div')`
  font-size: 14px;
  color: #666666;
`

export const TitleBlock = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

export const TopBlock = styled('div')<{ isEditMode: boolean }>`
  margin-bottom: ${(props) => (props.isEditMode ? '25px' : 'unset')};
`

export const RowAntd = styled(Row)``

export const ColAntd = styled(Col)``

export const FormItem = styled(AntForm.Item)`
  margin-bottom: 0px;
`

export const Radio = styled(AntdRadio)`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
`

export const RadioGroup = styled(AntdRadio.Group)`
  width: 100%;

  .ant-radio {
    align-self: start;
    padding-right: 5px;
    margin-top: 5px;
  }

  .ant-radio-wrapper-checked {
    border: 1px solid #009640 !important;
  }

  .ant-radio-wrapper {
  }
`

export const RadioTitle = styled('div')`
  display: flex;
  font-size: 14px;
  font-weight: 500;
`

export const RadioSubTitle = styled('div')`
  color: #666666;
  font-size: 14px;
  font-weight: 400;
`

export const OnText = styled('span')`
  color: #009400;
  padding-right: 5px;
`

export const OffText = styled('span')`
  color: #ef2605;
  padding-right: 5px;
`

export const StatusBadge = styled('span')<{ isActive: boolean }>`
  padding: 2px 8px 2px 6px;
  border-radius: 16px;
  background-color: ${(props) => (props.isActive ? '#e6f6ea' : '#FCEDED')};
  color: ${(props) => (props.isActive ? '#009400' : '#EB8181')};
`
