import React from 'react'

import {
  Container,
  InformationTitle,
  Information,
  Block,
  MoreInfoIcon,
  MoreInfoOutlineIcon,
  EvBadge,
} from './styles'
import { InformationGroupInterface } from './interfaces'
import copyToClipboard from '@/libs/utils/copy-to clipboard.util'
import { Tooltip } from 'antd'

const InformationGroup = ({
  information,
  fontColor,
  infoIcon,
  evBadge,
  infoIconFunction,
  marginBottomUnset,
  dataTestId,
  tooltip,
  tooltipMessage,
  copyDetailMessage,
}: InformationGroupInterface) => {
  const handleCopy = () => copyToClipboard(information.detail || '')

  return (
    <Container marginBottomUnset={marginBottomUnset}>
      <InformationTitle>
        {information.title}{' '}
        {tooltip && (
          <Tooltip title={tooltipMessage}>
            <MoreInfoOutlineIcon />
          </Tooltip>
        )}
      </InformationTitle>
      <Block>
        <Tooltip title={copyDetailMessage ? 'คลิกเพื่อคัดลอก' : ''} placement="right">
          <Information
            fontColor={fontColor}
            data-testid={dataTestId}
            onClick={copyDetailMessage ? handleCopy : undefined}
            style={copyDetailMessage ? { cursor: 'pointer' } : undefined}
          >
            {information.detail}
            {evBadge && <EvBadge color="blue">EV</EvBadge>}
          </Information>
        </Tooltip>
        {infoIcon && <MoreInfoIcon onClick={infoIconFunction} />}
      </Block>
    </Container>
  )
}

export default InformationGroup
