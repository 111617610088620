import { useRecoilState } from 'recoil'

import confirmModalAtom from '@/recoil/confirmModal'

function ConfirmModalHook() {
  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalAtom)

  const onModalInvisible = () => {
    setConfirmModal({
      isVisible: false,
      onOkFunction: Function,
      okButtonProps: { type: 'primary', danger: false },
      title: '',
      bodyText: '',
      cancelText: 'ยกเลิก',
      dataTestId: undefined,
    })
  }

  return {
    confirmModal,
    setConfirmModal,
    onModalInvisible,
  }
}

export default ConfirmModalHook
