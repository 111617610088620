import backofficeGetActiveHealthPartnerAll from './backofficeGetActiveHealthPartnerAll/backofficeGetActiveHealthPartnerAll.gql'
import backofficeGetActiveHealthPartnerPagination from './backofficeGetActiveHealthPartnerPagination/backofficeGetActiveHealthPartnerPagination.gql'
import backofficeGetActiveHealthPartnerById from './backofficeGetActiveHealthPartnerById/backofficeGetActiveHealthPartnerById.gql'
import backofficeGetRecentActiveHealthBonusCriteria from './backofficeGetRecentActiveHealthBonusCriteria/backofficeGetRecentActiveHealthBonusCriteria.gql'
import backofficeGetActiveHealthPartnerBranchPaginationWithScanCount from './backofficeGetActiveHealthPartnerBranchPaginationWithScanCount/backofficeGetActiveHealthPartnerBranchPaginationWithScanCount.gql'
import backofficeGetQrByActiveHealthPartnerBranchId from './backofficeGetQrByActiveHealthPartnerBranchId/backofficeGetQrByActiveHealthPartnerBranchId.gql'
import backofficeGetActiveHealthPartnerCategoryPagination from './backofficeGetActiveHealthPartnerCategoryPagination/backofficeGetActiveHealthPartnerCategoryPagination.gql'
import backofficeCreateActiveHealthPartner from './backofficeCreateActiveHealthPartner/backofficeCreateActiveHealthPartner.gql'
import backofficeUpdateActiveHealthPartner from './backofficeUpdateActiveHealthPartner/backofficeUpdateActiveHealthPartner.gql'

const activeHealthPartner = {
  query: {
    backofficeGetActiveHealthPartnerCategoryPagination,
    backofficeGetActiveHealthPartnerAll,
    backofficeGetActiveHealthPartnerPagination,
    backofficeGetActiveHealthPartnerById,
    backofficeGetRecentActiveHealthBonusCriteria,
    backofficeGetActiveHealthPartnerBranchPaginationWithScanCount,
    backofficeGetQrByActiveHealthPartnerBranchId,
  },
  mutation: {
    backofficeCreateActiveHealthPartner,
    backofficeUpdateActiveHealthPartner,
  },
}

export default activeHealthPartner
