import { useRecoilValue } from 'recoil'
import { Outlet, useParams } from 'react-router-dom'

import { Container } from './styles'
import WellBeingPage from '@/components/feature/WellBeingPage/WellBeingPage'
import { BACKOFFICE_USER_PERMISSION } from '@/constant/BACKOFFICE_PERMISSION'
import UnauthorizedPage from '../Unauthorized/Unauthorized'
import { validatePerm } from '@/libs/validatePermission'
import userAtom from '@/recoil/user/atom'

const WellBeing = () => {
  const user = useRecoilValue(userAtom)
  const { partnerId } = useParams<{ partnerId: string }>()

  return (
    <>
      {validatePerm([BACKOFFICE_USER_PERMISSION.WBE010101], user.permissions) ? (
        <Container>{partnerId ? <Outlet /> : <WellBeingPage />}</Container>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  )
}

export default WellBeing
