import { Layer } from 'leaflet'
import { v4 as uuidv4 } from 'uuid'
import { Geometry, Feature, GeoJsonProperties } from 'geojson'
import { getDistance } from 'geolib'
import { PolicyDetailContext } from '@/contexts'
import { useContext, useEffect, useRef, useState } from 'react'
import { formatThaiLocaleDateWithTimeMinute } from '@/libs/date'
import { useCoveragesPeriod } from '@/services/coverage/useCoveragesPeriod'
import {
  CoveragesPeriodResponseType,
  PeriodWithIdType,
} from '@/services/coverage/useCoveragesPeriod/types'
import { TVI_STATUS_TEXTS, TviStatusEnum } from '@/constant/RETURN_TIME'

function HistoryCarUsageDetailHook() {
  const { setSelectCoverageId, selectedCoverageId } = useContext(PolicyDetailContext)
  const [data, setData] = useState<CoveragesPeriodResponseType>()
  const [periodId, setPeriodId] = useState<string>()
  const [tviStatus, setTviStatus] = useState<TviStatusEnum>()
  const periodIdRef = useRef<string>()
  const [geoJsonData, setGeoJsonData] = useState<GeoJSON.FeatureCollection<Geometry>>()
  const coveragesPeriod = useCoveragesPeriod({ coverageId: selectedCoverageId })

  const backToPolicyDetail = () => {
    setSelectCoverageId('')
  }

  const handleOnClickTimeLine = (data: PeriodWithIdType) => {
    setPeriodId(data?.id)
    setTviStatus(undefined)
  }

  const handleOnClickModeSummarize = (status?: TviStatusEnum) => {
    setPeriodId(undefined)
    setTviStatus(status)
  }

  const handleOnViewLocation = (id: string) => {
    if (periodId === id) {
      setPeriodId(undefined)
      return
    }

    setPeriodId(id)
    setTviStatus(undefined)
  }

  const createFeature = (
    coordinates: number[][],
    item: PeriodWithIdType,
  ): Feature<Geometry, GeoJsonProperties> => {
    return {
      type: 'Feature',
      properties: {
        id: item.id,
        duration: item.duration,
        tviStatus: item.tviStatus,
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
      },
      geometry: {
        type: 'LineString',
        coordinates,
      },
    }
  }

  const convertToGeoJson = (data: PeriodWithIdType[]): GeoJSON.FeatureCollection<Geometry> => {
    const thresholdDistance = 1500 // in meters

    const features: Feature<Geometry, GeoJsonProperties>[] = []

    data.forEach((item) => {
      let currentLineString: number[][] = []

      item.events.forEach((event, index) => {
        const { lat, lng } = event
        const currentPoint = [lng, lat]

        if (index === 0) {
          currentLineString.push(currentPoint)
        } else {
          const previousEvent = item.events[index - 1]
          const distance = getDistance(
            { latitude: previousEvent.lat, longitude: previousEvent.lng },
            { latitude: lat, longitude: lng },
          )

          if (distance <= thresholdDistance) {
            currentLineString.push(currentPoint)
          } else {
            if (currentLineString.length > 1) {
              const feature = createFeature(currentLineString, item)

              features.push(feature)
            }
            currentLineString = [currentPoint]
          }
        }
      })

      if (currentLineString.length > 1) {
        const feature = createFeature(currentLineString, item)

        features.push(feature)
      }
    })

    return {
      type: 'FeatureCollection',
      features,
    }
  }

  const popupLayer = (feature: Feature<Geometry, any>) => `
  <div style="font-family: 'Prompt';">
    <strong>สถานะ:</strong> ${
      TVI_STATUS_TEXTS[feature.properties.tviStatus as TviStatusEnum] || 'N/A'
    }<br/>
    <strong>ระยะเวลา:</strong> ${feature.properties.duration} นาที<br/>
    <strong>เวลาเริ่มต้น:</strong> ${formatThaiLocaleDateWithTimeMinute(
      feature.properties.startDateTime,
    )}<br/>
    <strong>เวลาสิ้นสุด:</strong> ${formatThaiLocaleDateWithTimeMinute(
      feature.properties.endDateTime,
    )}<br/>
  </div>
`

  // For map
  const onEachFeature = (feature: Feature<Geometry, any>, layer: Layer) => {
    layer.on({
      click: () => {
        if (feature.properties.id !== periodIdRef.current) {
          const data: PeriodWithIdType = feature.properties
          handleOnClickTimeLine(data)
        } else {
          setPeriodId(undefined)
        }
      },
      mouseover: () => {
        layer.bindPopup(popupLayer(feature), { minWidth: 180 }).openPopup()
      },
    })
  }

  useEffect(() => {
    if (coveragesPeriod.data) {
      const data = coveragesPeriod.data

      const periods = data.periods.map((period) => ({
        ...period,
        id: uuidv4(),
      }))

      setData({
        ...data,
        periods,
      })
      if (periods.length > 0) {
        setGeoJsonData(convertToGeoJson(periods))
      }
    }
  }, [coveragesPeriod.data])

  useEffect(() => {
    periodIdRef.current = periodId

    // when periodId is selected, filter the data
    if (data?.periods && data.periods.length > 0) {
      if (periodId) {
        setGeoJsonData(convertToGeoJson(data.periods.filter((item) => item.id === periodId)))
      } else {
        setGeoJsonData(convertToGeoJson(data.periods))
      }
    }
  }, [periodId, data?.periods])

  return {
    backToPolicyDetail,
    data,
    periodId,
    tviStatus,
    handleOnClickTimeLine,
    handleOnClickModeSummarize,
    handleOnViewLocation,
    onEachFeature,
    geoJsonData,
    setPeriodId,
  }
}

export default HistoryCarUsageDetailHook
