import ReturnTimeReasonModalHook from '@/components/feature/ReturnTimePage/ReturnTimeReasonModal/ReturnTimeReasonModal.hook'
import { Modal } from 'antd'
import Table from '@/components/common/Table'
import { CloseOutlined } from '@ant-design/icons'

type ReturnTimeReasonModalProps = {
  open: boolean
  ppuMotorReturnTimeId: string
  closeModal: () => void
}

const ReturnTimeReasonModal = ({
  open,
  closeModal,
  ppuMotorReturnTimeId,
}: ReturnTimeReasonModalProps) => {
  const { columns, returnTimeReason } = ReturnTimeReasonModalHook({ ppuMotorReturnTimeId })
  return (
    <Modal
      data-testid="return-time-reason-modal"
      open={open}
      closeIcon={<CloseOutlined />}
      onCancel={closeModal}
      title="คืนชั่วโมง"
      footer={null}
      width={650}
    >
      <Table
        rowKey="id"
        columns={columns}
        loading={returnTimeReason.isFetching}
        dataSource={returnTimeReason ? returnTimeReason.data : undefined}
      />
    </Modal>
  )
}

export default ReturnTimeReasonModal
