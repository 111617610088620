import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { Layer, LeafletMouseEvent, geoJSON } from 'leaflet'
import { Geometry, Feature, GeoJsonObject } from 'geojson'
import Map from '@/components/common/Map'
import { Container } from './styles'
import HistoryCarUsageMapHook from './HistoryCarUsageMap.hook'

const FitBounds: React.FC<{
  geoJson: GeoJsonObject
  setPeriodId: (id: string | undefined) => void
}> = ({ geoJson, setPeriodId }) => {
  const map = useMap()

  useEffect(() => {
    if (geoJson) {
      const geoJsonLayer = geoJSON(geoJson)
      const bounds = geoJsonLayer.getBounds()

      if (bounds.isValid()) {
        map.fitBounds(bounds) // Fit map to the GeoJSON bounds
      }
    }
  }, [geoJson, map])

  useEffect(() => {
    if (map) {
      const handleClickOutsideFeature = (e: LeafletMouseEvent) => {
        const clickedLayer = e.target
        if (!clickedLayer.feature) {
          setPeriodId(undefined)
        }
      }

      map.on('click', handleClickOutsideFeature)

      return () => {
        map.off('click', handleClickOutsideFeature)
      }
    }
  }, [map])

  return null
}

function HistoryCarUsageMap({
  geoJsonData,
  onEachFeature,
  setPeriodId,
}: {
  geoJsonData?: GeoJSON.FeatureCollection<Geometry>
  onEachFeature: (feature: Feature<Geometry, any>, layer: Layer) => void
  setPeriodId: (id: string | undefined) => void
}) {
  const { style } = HistoryCarUsageMapHook()

  return (
    <Container>
      <Map geoJson={geoJsonData} geoJsonStyle={style} onEachFeature={onEachFeature}>
        {geoJsonData && <FitBounds geoJson={geoJsonData} setPeriodId={setPeriodId} />}
      </Map>
    </Container>
  )
}

export default HistoryCarUsageMap
