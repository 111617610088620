import { BodySpan, Modal } from './styles'
import ConfirmModalHook from './ConfirmModal.hook'

function ConfirmModal() {
  const {
    confirmModal: {
      isVisible,
      onOkFunction,
      title,
      okText,
      cancelText,
      okButtonProps,
      bodyText,
      dataTestId,
    },
    onModalInvisible,
  } = ConfirmModalHook()

  return (
    <Modal
      title={title}
      open={isVisible}
      closable={false}
      cancelText={cancelText}
      okText={okText}
      okButtonProps={okButtonProps}
      onOk={onOkFunction}
      onCancel={onModalInvisible}
      zIndex={1060}
      data-testid={dataTestId}
      centered
    >
      <BodySpan>{bodyText}</BodySpan>
    </Modal>
  )
}

export default ConfirmModal
