import styled from '@emotion/styled'
import { Badge, Col, Row } from 'antd'

export const RowAntd = styled(Row)``

export const ColAntd = styled(Col)``

export const Container = styled('div')`
  padding: 10px 0px;
`

export const ModeCard = styled('div')<{ isAction?: boolean }>`
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid;
  user-select: none;
  border-color: ${(props) => (props.isAction ? '#009640' : '#e5e5e5')};
`

export const ModeStatus = styled(Badge)``

export const ModeBody = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
`

export const ModeText = styled('div')`
  display: flex;
  color: #adadad;
`

export const ModeMinute = styled('div')`
  color: #000000;
  margin-right: 5px;
`
