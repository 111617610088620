import WellBeingPartnerTable from '@/components/feature/WellBeingPage/WellBeingPartnerTable'
import CampaignPeriod from './CampaignPeriod'
import { Title } from './styles'

const WellBeingPage = () => {
  return (
    <>
      <Title>จัดการพาร์ทเนอร์ Well-Being</Title>
      <CampaignPeriod />
      <WellBeingPartnerTable />
    </>
  )
}

export default WellBeingPage
