import config from '@/config'
import { httpClientPrivate } from '@/libs/httpClient'
import message from '@/libs/message'
import { useMutation } from '@tanstack/react-query'

const deleteTempPassword = async (userId: string) => {
  const response = await httpClientPrivate.delete(
    `${config.restUrl}/v1/backoffice/user/${userId}/temp-password`,
  )
  return response.data
}

const useDeleteTempPassword = (userId: string) => {
  const mutationKey = ['useDeleteTempPassword', userId]
  const mutationFn = async () => deleteTempPassword(userId)
  return useMutation({
    mutationFn,
    mutationKey,
    onSuccess: () => message.success('ลบรหัสผ่านชั่วคราวสำเร็จ'),
    onError: () => message.error('ลบรหัสผ่านชั่วคราวไม่สำเร็จ หรือหมดอายุแล้ว'),
  })
}

export { deleteTempPassword, useDeleteTempPassword }
